import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  simplifications: props => ({
    width: '95%',
    fontFamily: props.typography.fontEditorPrimary,
    fontWeight: props.typography.fontWeightRegular,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.primary.main
  }),
  accordionSummary: {
    padding: '15px',
    alignItems: 'flex-start',
    '&.Mui-expanded': {
      minHeight: '20px'
    },
    '& .MuiAccordionSummary-content': {
      marginTop: 0,
      marginBottom: 0,
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0
      }
    }
  },
  accordionDetails: {
    padding: 0,
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));
