import { makeStyles } from '@mui/styles';

import { displayBorder } from 'helpers';

const important = value => {
  return `${value} !important`;
};

export const useStyles = makeStyles({
  iconButton: {
    backgroundColor: props => important(props.palette.gray.light),
    '&:hover': {
      color: props => important(props.palette.gray.medium),
      backgroundColor: props => important(props.palette.gray.light)
    }
  },
  backButtonForDocumentPage: {
    '&:hover': {
      color: props => important(props.palette.gray.medium),
      backgroundColor: props => important(props.palette.gray.light)
    }
  },
  flex: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  divider: {
    backgroundColor: props => props.palette.gray.lighter
  },
  documentName: props => ({
    fontSize: props.typography.pxToRem(16),
    fontFamily: props.typography.fontFamilyPrimarySemibold,
    color: props.palette.primary.main,
    marginLeft: '0.5rem'
  }),
  readabilityLevelText: props => ({
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(16),
    color: props.palette.common.white,
    paddingLeft: '17px',
    paddingRight: '17px'
  }),
  clauseHeaderStyle: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0rem',
    justifyContent: 'space-between',
    paddingLeft: '3.5rem'
  },
  clauseHeader: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '260px'
  },
  clauseContainer: props => ({
    [props.breakpoints.down(displayBorder.medium)]: {
      paddingTop: '4%'
    }
  }),
  indicatorAndEditorCardContainer: props => ({
    boxShadow: 'none',
    marginLeft: '3.5rem',
    width: '100%',
    height: 'calc(99vh - 13rem)',
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      height: 'calc(99.5vh - 13.2rem)',
      width: '100%'
    }
  }),
  commentsTab: props => ({
    padding: '5px',
    width: '100%',
    height: 'calc(94vh - 12.8rem)',
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      height: 'calc(90.5vh - 10rem)',
      width: '100%'
    }
  }),
  sidebarContainer: props => ({
    paddingRight: '10px',
    paddingLeft: '10px',
    width: '25%',
    [props.breakpoints.only(displayBorder.extraLargeModified)]: {
      width: '20%'
    }
  }),
  width20: props => ({
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      width: '20%'
    },
    width: '1%',
    marginLeft: '1.5%',
    [props.breakpoints.only(displayBorder.extraLargeModified)]: {
      width: '15%',
      marginLeft: '1%'
    }
  }),
  width80: props => ({
    width: '76%',
    [props.breakpoints.only(displayBorder.extraLargeModified)]: {
      width: '80%'
    },
    [props.breakpoints.down(displayBorder.large)]: {
      width: '95%'
    }
  }),
  width80Comments: props => ({
    width: '75%',
    [props.breakpoints.only(displayBorder.extraLargeModified)]: {
      width: '80%'
    },
    [props.breakpoints.down(displayBorder.large)]: {
      width: '95%'
    }
  }),
  width90: props => ({
    width: '90%',
    [props.breakpoints.only(displayBorder.extraLargeModified)]: {
      width: '82%'
    },
    [props.breakpoints.down(displayBorder.large)]: {
      width: '95%'
    }
  }),
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '0.4rem',
    paddingLeft: '1rem'
  },
  header: props => ({
    fontSize: props.typography.pxToRem(12),
    color: props.palette.gray.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular
  }),
  documentNameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  readabilityText: props => ({
    marginRight: '0.5rem',
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.primary.main
  }),
  button: {
    border: '1px solid',
    borderColor: props => props.palette.gray.light,
    cursor: 'pointer',
    borderRadius: 8,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem'
  },
  arrow: props => ({
    color: props.palette.gray.dark,
    opacity: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: props.palette.gray.light,
      borderRadius: '50%'
    }
  }),
  editDivider: {
    height: '1.5rem',
    marginLeft: '0.5625em',
    marginRight: '0.5625em',
    color: props => props.palette.gray.light
  },
  editLabel: props => ({
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    color: props.palette.gray.main,
    marginLeft: '1rem',
    fontSize: props.typography.pxToRem(14)
  }),
  mobileHeader: {
    width: '100%',
    paddingRight: '1rem',
    paddingLeft: '1rem'
  },
  mobileBackIcon: {
    padding: '0.8rem',
    border: props => '1px solid ' + props.palette.gray.main,
    borderRadius: '50%',
    width: '2.78rem',
    height: '2.78rem'
  },
  mobileExportIcon: {
    padding: '0.8rem',
    borderRadius: '50%',
    width: '2.78rem',
    height: '2.78rem',
    backgroundColor: props => props.palette.gray.lighter
  },
  mobileSubHeader: {
    width: '100%',
    padding: '1rem 1rem 1.5rem 1rem'
  },
  complexityMapSwitch: {
    display: 'flex',
    alignItems: 'center'
  },
  complexityMapLabel: props => ({
    fontSize: props.typography.pxToRem(14),
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    paddingBottom: '6px'
  }),
  suggestionsContainer: props => ({
    marginTop: '5.5rem',
    height: 'fit-content',
    maxHeight: 'calc(99vh - 13rem)',
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      width: '18%'
    },
    width: '21%',
    alignItems: 'flex-end'
  }),
  suggestionsContainerComments: {
    height: 'fit-content',
    maxHeight: 'calc(93vh - 13rem)',
    alignItems: 'flex-end'
  },
  suggestionsList: {
    paddingRight: '10px',
    width: '100%',
    marginTop: '15px'
  },
  suggestionsHeader: props => ({
    color: props.palette.gray.main,
    fontSize: '16px',
    fontFamily: props.typography.fontFamilyPrimarySemibold,
    alignSelf: 'baseline',
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      marginRight: '20%'
    },
    marginRight: '32%'
  }),
  suggestionHintBox: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    columnGap: '15px',
    padding: '15px',
    marginTop: '15px',
    borderRadius: '8px',
    boxShadow: props => `0px 0px 10px 0px ${props.palette.gray.light}`
  },
  suggestionHintBoxText: {
    fontFamily: props => props.typography.fontFamilyPrimaryRegular,
    fontSize: '12px',
    flex: 1
  },
  loaderText: props => ({
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(15),
    width: '550px',
    wordWrap: 'break-word',
    lineHeight: 2,
    bottom: '12.5rem',
    [props.breakpoints.up(displayBorder.extraLargeModified)]: {
      bottom: '18rem'
    }
  }),
  menuItem: props => ({
    color: props.palette.primary.main,
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    height: '40px',
    width: '180px',
    '&:hover': {
      backgroundColor: props.palette.gray.lightest
    },
    '&:hover > *': {
      color: props.palette.primary.main
    }
  }),
  icon: {
    '&.MuiListItemIcon-root': {
      minWidth: '30px'
    }
  }
});
