import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { CustomCheckbox } from 'components';
import { faSortDown } from 'resources/fontawesome/solid/faSortDown';
import { useStoreFields } from 'stores';

const useStyles = makeStyles({
  viewButton: props => ({
    cursor: 'pointer',
    display: 'flex',
    fontSize: props.typography.pxToRem(12),
    color: props.palette.gray.dark,
    fontFamily: props.typography.fontFamilyPrimaryMedium,
    fontWeight: props.typography.fontWeightRegular,
    gap: '5px',
    padding: '6px 15px',
    borderRadius: '20px',
    '&:hover': {
      background: props.palette.gray.lighter
    }
  }),
  dropdownItem: {
    gap: '10px',
    padding: '10px 15px'
  },
  dropdownItemText: props => ({
    fontSize: props.typography.pxToRem(14),
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    lineHeight: '20px',
    color: props.palette.primary.main
  }),
  dropdown: {
    '& .MuiMenu-list': {
      padding: '0px'
    }
  },
  paper: {
    boxShadow: `0px 0px 10px 0px ${'rgba(99, 115, 129, 0.3)'}`,
    marginTop: '10px'
  }
});

export const ViewExclusionsDropdown = ({ disabled, isHighlightActive }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles(theme);

  const { quillEditor, setShouldShowExcludedText, shouldShowExcludedText, documentMetadata } =
    useStoreFields([
      'quillEditor',
      'setShouldShowExcludedText',
      'shouldShowExcludedText',
      'documentMetadata'
    ]);

  const exclusions = useMemo(() => documentMetadata?.exclusions || [], [documentMetadata]);

  useEffect(() => {
    if (shouldShowExcludedText && isHighlightActive) {
      exclusions.forEach(({ startIndex, endIndex }) => {
        quillEditor?.formatText(startIndex, endIndex - startIndex, 'excluded_text', true);
      });
    } else {
      quillEditor?.formatText(0, quillEditor?.getText().length, 'excluded_text', false);
    }
  }, [shouldShowExcludedText, exclusions, isHighlightActive]);

  return (
    <Box>
      <Box
        data-testid='viewExclusions'
        disabled={disabled}
        ref={ref}
        onClick={() => setIsOpen(true)}
        className={classes.viewButton}
        sx={{
          backgroundColor: isOpen && theme.palette.gray.lighter
        }}>
        {t('view')}
        <FontAwesomeIcon icon={faSortDown} />
      </Box>

      <Menu
        data-testid='viewExclusionsDropdown'
        className={classes.dropdown}
        PaperProps={{
          className: classes.paper
        }}
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <MenuItem
          data-testid='showExclusions'
          onClick={() => setShouldShowExcludedText(!shouldShowExcludedText)}
          disableRipple
          className={classes.dropdownItem}>
          <CustomCheckbox dataTestId='showExclusionsCheckbox' checked={shouldShowExcludedText} />
          <Typography className={classes.dropdownItemText}>
            {t('showTextExcludedFromScoring')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

ViewExclusionsDropdown.propTypes = {
  disabled: PropTypes.bool,
  isHighlightActive: PropTypes.bool
};
