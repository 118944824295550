import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, MenuItem, Stack, InputBase, Typography, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { Icons, MyAvatar, TextFieldStyle } from 'components';
import { toastMessage } from 'helpers';
import { UserContext } from 'providers';
import { loginService, masterUserService } from 'services';

export const AccountSwitchDropdown = ({ touched }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [t] = useTranslation('common');
  const { user } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [tenants, setTenants] = useState(user.tenants.filter(ten => !ten.isDefault));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const Search = styled('div')(() => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadiusLg,
    backgroundColor: theme.palette.gray.white,
    marginLeft: 0,
    width: isMobile ? '220px' : '366px',
    height: '38px',
    border: '1px solid ' + theme.palette.gray.light,
    justifyContent: 'center',
    display: 'flex'
  }));

  const SearchIconWrapper = styled('div')(() => ({
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center'
  }));

  useEffect(() => {
    setTenants(
      search === ''
        ? user.tenants.filter(ten => !ten.isDefault)
        : user.tenants.filter(
            ten => ten.name.toUpperCase().includes(search.toUpperCase()) && !ten.isDefault
          )
    );
  }, [search]);

  const useStyles = makeStyles({
    searchField: {
      height: '60px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.gray.white,
      borderBottom: '0.5px solid ' + theme.palette.gray.lighter,
      marginTop: '-8px',
      paddingTop: '10px',
      marginBottom: tenants.length === 0 ? '-8px' : undefined
    },
    searchFieldInput: {
      width: !isMobile && '280px',
      height: '38px',
      marginLeft: '20px',
      fontSize: '12px',
      fontFamily: theme.typography.fontFamilyPrimaryRegular
    },
    closeIcon: {
      width: '25px',
      height: '25px',
      backgroundColor: theme.palette.gray.white,
      borderRadius: '50%',
      cursor: 'pointer !important',
      color: theme.palette.gray.main,
      padding: '0.3rem',
      alignSelf: 'center'
    },
    menuItems: {
      height: '60px',
      borderBottom: '0.5px solid ' + theme.palette.gray.lighter,
      fontSize: theme.typography.pxToRem(14),
      fontFamily: theme.typography.fontFamilyPrimarySemibold,
      '&:hover': {
        backgroundColor: theme.palette.gray.lightest,
        color: theme.palette.primary.main
      },
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  });

  const classes = useStyles();

  const handleSelectColor = isTouched => {
    if (isTouched) {
      return theme.palette.gray.dark;
    } else {
      return theme.palette.gray.main;
    }
  };

  const getUserData = () => {
    loginService.getUserPermissionDetails().then(resPermissions => {
      if (resPermissions) {
        user.permissions = resPermissions.data.permissions;
        navigateToDefaultPage();
      }
    });
  };

  const navigateToDefaultPage = () => {
    navigate('/dashboard');
    location.reload();
  };

  const switchTenantMutation = useMutation(
    async id => {
      masterUserService.switchTenant({ newTenantId: id });
    },
    {
      onSuccess: () => {
        getUserData();
        toastMessage(enqueueSnackbar, closeSnackbar, t('tenantSuccess'), 'success');
      },
      onError: () => {
        toastMessage(enqueueSnackbar, closeSnackbar, t('tenantError'), 'error');
      }
    }
  );

  const handleClick = id => {
    switchTenantMutation.mutate(id);
  };

  return (
    <Stack
      sx={{
        pl: !isMobile && '25px',
        pt: '3px',
        width: isMobile ? '100%' : '500px',
        marginTop: '5px !important'
      }}>
      <TextFieldStyle
        onClick={e => e.stopPropagation()}
        select
        sx={{
          marginRight: !isMobile && '20px',
          '& .MuiOutlinedInput-root': {
            bgcolor: theme.palette.gray.white,
            color: handleSelectColor(touched),
            height: '60px',
            fontSize: theme.typography.pxToRem(14),
            '& fieldset': {
              borderColor: theme.palette.gray.light
            },
            '&:hover fieldset': {
              borderColor: theme.palette.gray.main
            },
            '& .MuiSelect-select': {
              fontFamily: theme.typography.fontFamilyPrimarySemibold,
              fontSize: theme.typography.pxToRem(14),
              display: 'flex',
              alignItems: 'center'
            }
          },
          '& .MuiSelect-icon': {
            border: '0px !important'
          },
          '& .Mui-focused': {
            border: '1px solid',
            borderColor: theme.palette.gray.main,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }
        }}>
        {
          <div className={classes.searchField} onKeyDown={e => e.stopPropagation()}>
            <Search>
              <SearchIconWrapper>
                <Icons iconName='searchIcon' />
              </SearchIconWrapper>
              <InputBase
                value={search}
                autoFocus
                className={classes.searchFieldInput}
                onClickCapture={e => e.stopPropagation()}
                onChange={e => setSearch(e.target.value)}
                id='searchInput'
                placeholder={t('searchTenant')}
              />
              <IconButton
                aria-label='close'
                className={classes.closeIcon}
                onClick={e => {
                  setSearch('');
                  e.stopPropagation();
                }}>
                <CloseIcon style={{ width: '16px', height: '16px' }} />
              </IconButton>
            </Search>
          </div>
        }
        {user &&
          tenants.map((item, index) => (
            <MenuItem
              disableRipple
              style={{
                marginBottom: tenants.length - 1 === index ? '-8px' : undefined
              }}
              className={classes.menuItems}
              key={item.id}
              onClick={() => {
                setSearch('');
                handleClick(item.id);
              }}
              value={item.id}>
              <MyAvatar displayName={item.name} sx={{ width: 36, height: 36, mr: '15px' }} />
              {item.name}
              &nbsp;
              {!item.isActive && (
                <Typography
                  sx={{
                    fontStyle: 'italic',
                    fontSize: theme.typography.pxToRem(14),
                    fontFamily: theme.typography.fontFamilyPrimarySemibold
                  }}>
                  {`(${t('inactive')})`}
                </Typography>
              )}
            </MenuItem>
          ))}
      </TextFieldStyle>
    </Stack>
  );
};

AccountSwitchDropdown.propTypes = {
  touched: PropTypes.any
};
