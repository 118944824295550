import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { KeyIssues } from 'components';
import {
  checkFeatureFlag,
  COMPLEX_CONCEPTS,
  COMPLEX_SENTENCES,
  COMPLEX_WORDS,
  COMPLEXITY_SCORE,
  featureFlagsEnum
} from 'helpers';
import { UserContext } from 'providers';

import { IntelligibilityScore } from './IntelligibilityScore';
import { useStyles } from './IntelligibilitySidebar.css';
import { PersonasScoring } from './PersonasScoring';
import { MetricsRating } from '../MetricsRating/MetricsRating';

export const IntelligibilityScoreInfo = ({
  intelligibilityData,
  isPlugin,
  isLoading,
  displayComplexConcepts
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);
  const { featureFlags } = useContext(UserContext);

  const showPersonasScorings = checkFeatureFlag(featureFlags, featureFlagsEnum.PERSONAS_SCORING);
  const showComplexConcept = checkFeatureFlag(
    featureFlags,
    featureFlagsEnum.COMPLEX_CONCEPTS_DISPLAY
  );
  return (
    <>
      <IntelligibilityScore intelligibilityData={intelligibilityData} isLoading={isLoading} />
      <Divider sx={{ m: '15px 0px', color: theme.palette.gray.light }} />
      <Typography className={classes.headerStyle}>{t('complexityMetrics')}</Typography>
      <MetricsRating
        metricType={COMPLEXITY_SCORE}
        textMetric={intelligibilityData.textMetrics?.cefr}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={COMPLEX_SENTENCES}
        textMetric={{
          ...intelligibilityData.textMetrics?.complexSentences,
          value: intelligibilityData.textMetrics?.complexSentences.value + '%'
        }}
        isComplexWordsAndSentences={true}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={COMPLEX_WORDS}
        textMetric={{
          ...intelligibilityData.textMetrics?.complexWords,
          value: intelligibilityData.textMetrics?.complexWords.value + '%'
        }}
        isComplexWordsAndSentences={true}
        isLoading={isLoading}
      />
      {(showComplexConcept || displayComplexConcepts) && (
        <MetricsRating
          metricType={COMPLEX_CONCEPTS}
          textMetric={intelligibilityData.textMetrics?.complexConcepts}
          showTooltips={false}
          isLoading={isLoading}
          isNeutral
        />
      )}
      {isPlugin && (
        <>
          <Divider sx={{ m: '15px 0px', color: theme.palette.gray.light }} />
          <KeyIssues
            intelligibilityData={intelligibilityData}
            isPlugin={isPlugin}
            displayComplexConcepts={displayComplexConcepts}
          />
        </>
      )}
      {showPersonasScorings && intelligibilityData?.personas.length !== 0 && (
        <>
          <Divider sx={{ m: '15px 0px', color: theme.palette.gray.light }} />
          <PersonasScoring intelligibilityData={intelligibilityData} />
        </>
      )}
    </>
  );
};

IntelligibilityScoreInfo.propTypes = {
  intelligibilityData: PropTypes.object,
  isPlugin: PropTypes.bool,
  isLoading: PropTypes.bool,
  displayComplexConcepts: PropTypes.bool
};
