import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons, ListHead, LoadingAnimation, Scrollbar } from 'components';

export const ComplexContent = ({
  label,
  intelligibilityData,
  noDataIcon,
  noDataText,
  isPlugin,
  isLoading
}) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const contentType = label === 'commonComplexWords' ? 'word' : 'concept';
  const DOCUMENTS_TABLE_HEAD = [
    { id: 'id', width: '0%', align: 'left' },
    { id: contentType, label: t(contentType), width: '60%', align: 'left' },
    { id: 'count', label: t('count'), width: '40%', align: 'right' }
  ];

  const isDocumentNotFound = intelligibilityData.length === 0;
  const complexContent = intelligibilityData[label];

  return (
    <>
      <Typography
        data-testid={label}
        sx={{
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilySecondaryBold,
          mt: '10px',
          mb: '20px'
        }}>
        {t(label)}
      </Typography>
      {complexContent.length ? (
        <>
          <Typography
            sx={{
              color: theme.palette.gray.dark,
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              fontSize: theme.typography.pxToRem(12)
            }}>
            {t(`${label}Blurb`)}
          </Typography>
          {isLoading && (
            <Stack sx={{ alignItems: 'center', justifyContent: 'center', pt: '30px' }}>
              <LoadingAnimation height={60} width={60} marginRight='5px' />
              <Typography
                sx={{
                  mt: '10px',
                  color: theme.palette.gray.main,
                  fontFamily: theme.typography.fontFamilySecondaryMedium,
                  fontSize: theme.typography.pxToRem(12)
                }}>
                {t('isLoadingResults')}
              </Typography>
            </Stack>
          )}
          {!isLoading && (
            <Scrollbar sx={{ maxHeight: isPlugin ? '400px' : window.innerHeight - 410 }}>
              <Table stickyHeader aria-label='sticky table'>
                {!isDocumentNotFound && (
                  <ListHead
                    labelCellXPadding='0px'
                    headLabel={DOCUMENTS_TABLE_HEAD}
                    rowCount={intelligibilityData.length}
                    selectAllDisabled
                    dataCy={'selectAllCommonComplexWordsCheckbox'}
                  />
                )}
                <TableBody>
                  {complexContent.slice(0, 10).map((row, index) => {
                    const { id, text, numberOfOccurrences } = row;
                    return (
                      <TableRow data-testid={id} key={id} tabIndex={-1}>
                        <TableCell
                          padding='none'
                          sx={{
                            py: '5px',
                            pl: '0px!important',
                            borderBottom: `0.5px dashed ${theme.palette.gray.light}`
                          }}>
                          <Typography
                            variant='caption'
                            noWrap
                            sx={{
                              fontFamily: theme.typography.fontFamilyPrimaryMedium,
                              textDecoration: 'none',
                              color: theme.palette.gray.dark,
                              fontSize: theme.typography.pxToRem(12)
                            }}>
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            pl: '5px',
                            py: '6px',
                            borderBottom: `0.5px dashed ${theme.palette.gray.light}`
                          }}
                          padding='none'
                          scope='row'>
                          <Typography
                            variant='caption'
                            noWrap
                            sx={{
                              fontFamily: theme.typography.fontFamilyPrimarySemibold,
                              textDecoration: 'none',
                              color: theme.palette.gray.dark,
                              overflow: 'visible'
                            }}>
                            {text}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            py: '5px',
                            borderBottom: `0.5px dashed ${theme.palette.gray.light}`,
                            '&.MuiTableCell-root': {
                              pr: '10px'
                            }
                          }}
                          align='right'
                          padding='none'>
                          <Typography
                            variant='caption'
                            noWrap
                            sx={{
                              fontFamily: theme.typography.fontFamilyPrimarySemibold,
                              textDecoration: 'none',
                              color: theme.palette.gray.dark
                            }}>
                            {numberOfOccurrences}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          )}
        </>
      ) : (
        <Stack sx={{ alignItems: 'center' }}>
          <Icons iconName={noDataIcon} />
          <Typography
            sx={{
              pt: '15px',
              fontFamily: theme.typography.fontFamilySecondaryMedium,
              fontSize: theme.typography.pxToRem(12),
              color: theme.palette.gray.main
            }}>
            {t(noDataText)}
          </Typography>
        </Stack>
      )}
    </>
  );
};

ComplexContent.propTypes = {
  intelligibilityData: PropTypes.object,
  label: PropTypes.string,
  noDataIcon: PropTypes.string,
  noDataText: PropTypes.string,
  isPlugin: PropTypes.bool,
  isLoading: PropTypes.bool
};
