import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { getIntelligibilityLevelDescription } from 'helpers';

import { CircularProgressDiagram } from './CircularProgressDiagram';
import { ImprovementInfo } from './ImprovementInfo';

export const IntelligibilityScore = ({ intelligibilityData, isLoading }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();

  return (
    <>
      <Typography
        data-testid='intelligibilityScore'
        sx={{
          my: '10px',
          color: theme.palette.gray.dark,
          fontFamily: theme.typography.fontFamilySecondaryBold,
          fontSize: theme.typography.pxToRem(14)
        }}>
        {t('intelligibilityScore')}
      </Typography>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '15px'
        }}>
        <Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', pb: '10px' }}>
            <CircleIcon
              sx={{
                width: '10px',
                height: '10px',
                color:
                  theme.palette[`intelligibility_Level${intelligibilityData.intelligibilityLevel}`]
                    .dark
              }}
            />
            <Typography
              variant='body2'
              sx={{
                marginLeft: '10px',
                color: theme.palette.primary.main,
                fontFamily: theme.typography.fontFamilySecondaryMedium
              }}>
              {t('intelligibility_level_' + intelligibilityData.intelligibilityLevel)}
            </Typography>
          </Stack>
          <Typography
            sx={{
              letterSpacing: '-0.5px',
              lineHeight: '15px',
              color: theme.palette.gray.dark,
              fontFamily: theme.typography.fontFamilyPrimaryRegular,
              fontSize: theme.typography.pxToRem(12)
            }}>
            <Trans components={{ b: <strong /> }}>
              {getIntelligibilityLevelDescription(t, intelligibilityData.score)}
            </Trans>
          </Typography>
        </Stack>
        <CircularProgressDiagram intelligibilityData={intelligibilityData} isLoading={isLoading} />
      </Grid>
      {intelligibilityData.intelligibilityImprovements !== 0 && (
        <>
          <ImprovementInfo
            title={'improvement'}
            description={intelligibilityData?.numberOfEdits}
            value={`${intelligibilityData.intelligibilityImprovements?.toLocaleString('en-US')}%`}
            circleColor={'intelligibility_Level1'}
            isPercentage
          />
          <ImprovementInfo
            title={'originalIntelligibilityScore'}
            description={intelligibilityData.baselineIntelligibilityLevel}
            value={intelligibilityData.baselineIntelligibilityScore}
            circleColor={'intelligibility_Level' + intelligibilityData.baselineIntelligibilityLevel}
          />
        </>
      )}
    </>
  );
};

IntelligibilityScore.propTypes = {
  intelligibilityData: PropTypes.object,
  isLoading: PropTypes.bool
};
