import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { ComplexContent, KeyIssues } from 'components';
import { IntelligibilityScoreInfo } from 'components/IntelligibilitySidebar//IntelligibilityScoreInfo';
import { useStyles } from 'components/IntelligibilitySidebar//IntelligibilitySidebar.css';
import { DownloadPDFButton } from 'components/IntelligibilitySidebar/DownloadPDFButton';
import { PermissionChecker } from 'components/PermissionChecker/PermissionChecker';
import { Scrollbar } from 'components/Scrollbar/Scrollbar';
import { TextDensityMetrics } from 'components/TextDensityMetrics/TextDensityMetrics';
import { checkFeatureFlag, intelligibilitySidebarTabs, permissions } from 'helpers';
import { featureFlagsEnum } from 'helpers/feature-flags';
import { UserContext } from 'providers';

export const DocumentMetrics = ({
  documentId,
  header,
  intelligibilityData,
  isIntelligibilitySidebar
}) => {
  const [t] = useTranslation('common');
  const [selectedTab, setSelectedTab] = useState(
    intelligibilitySidebarTabs.INTELLIGIBILITY_INFO_TAB
  );
  const theme = useTheme();
  const classes = useStyles(theme);

  const { featureFlags } = useContext(UserContext);
  const isComplexConceptsFlagActive = checkFeatureFlag(
    featureFlags,
    featureFlagsEnum.COMPLEX_CONCEPTS_DISPLAY
  );
  return (
    <Grid
      container
      direction='column'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        height: isIntelligibilitySidebar && 'inherit'
      }}>
      <Grid item sx={{ flexGrow: 1 }}>
        {header()}
        <TabContext value={selectedTab}>
          <Scrollbar
            sx={{
              maxHeight: isIntelligibilitySidebar ? 'fit-content' : '450px',
              minHeight: !isIntelligibilitySidebar && '450px',
              maxWidth: isIntelligibilitySidebar ? '450px' : '480px',
              minWidth: isIntelligibilitySidebar ? '450px' : '480px'
            }}>
            <Tabs
              sx={{
                mx: '25px',
                width: isIntelligibilitySidebar && '410px',
                backgroundColor: theme.palette.gray.white
              }}
              variant='fullWidth'
              value={selectedTab}
              onChange={(_e, newValue) => setSelectedTab(newValue)}>
              <Tab
                disableRipple
                value={intelligibilitySidebarTabs.INTELLIGIBILITY_INFO_TAB}
                label={t('intelligibility')}
                className={classes.buttonStyle}
                sx={{
                  color:
                    selectedTab === intelligibilitySidebarTabs.INTELLIGIBILITY_INFO_TAB
                      ? theme.palette.gray.dark
                      : theme.palette.gray.main,
                  borderBottom: `2px solid ${
                    selectedTab === intelligibilitySidebarTabs.INTELLIGIBILITY_INFO_TAB
                      ? theme.palette.gray.dark
                      : '#E1E4E9'
                  }`
                }}
              />
              <Tab
                disableRipple
                value={intelligibilitySidebarTabs.TEXT_DENSITY}
                label={t('text')}
                className={classes.buttonStyle}
                sx={{
                  color:
                    selectedTab === intelligibilitySidebarTabs.TEXT_DENSITY
                      ? theme.palette.gray.dark
                      : theme.palette.gray.main,
                  borderBottom: `2px solid ${
                    selectedTab === intelligibilitySidebarTabs.TEXT_DENSITY
                      ? theme.palette.gray.dark
                      : '#E1E4E9'
                  }`
                }}
              />
              <Tab
                disableRipple
                value={intelligibilitySidebarTabs.KEY_ISSUES}
                label={t('keyIssues')}
                className={classes.buttonStyle}
                sx={{
                  textTransform: 'none',
                  color:
                    selectedTab === intelligibilitySidebarTabs.KEY_ISSUES
                      ? theme.palette.gray.dark
                      : theme.palette.gray.main,
                  borderBottom: `2px solid ${
                    selectedTab === intelligibilitySidebarTabs.KEY_ISSUES
                      ? theme.palette.gray.dark
                      : '#E1E4E9'
                  }`
                }}
              />
              {isComplexConceptsFlagActive && (
                <Tab
                  disableRipple
                  value={intelligibilitySidebarTabs.COMPLEX_CONCEPTS}
                  label={t('complexConcepts')}
                  className={classes.buttonStyle}
                  sx={{
                    textTransform: 'none',
                    color:
                      selectedTab === intelligibilitySidebarTabs.COMPLEX_CONCEPTS
                        ? theme.palette.gray.dark
                        : theme.palette.gray.main,
                    borderBottom: `2px solid ${
                      selectedTab === intelligibilitySidebarTabs.COMPLEX_CONCEPTS
                        ? theme.palette.gray.dark
                        : '#E1E4E9'
                    }`
                  }}
                />
              )}
            </Tabs>
            <Stack>
              <TabPanel value={intelligibilitySidebarTabs.INTELLIGIBILITY_INFO_TAB}>
                <IntelligibilityScoreInfo intelligibilityData={intelligibilityData} />
              </TabPanel>
              <TabPanel value={intelligibilitySidebarTabs.TEXT_DENSITY}>
                <TextDensityMetrics intelligibilityData={intelligibilityData} />
              </TabPanel>
              <TabPanel sx={{ pb: '0px' }} value={intelligibilitySidebarTabs.KEY_ISSUES}>
                <KeyIssues
                  intelligibilityData={intelligibilityData}
                  displayComplexConcepts={isComplexConceptsFlagActive}
                />
              </TabPanel>
              {isComplexConceptsFlagActive && (
                <TabPanel sx={{ pb: '0px' }} value={intelligibilitySidebarTabs.COMPLEX_CONCEPTS}>
                  <Box
                    sx={{
                      mt: '10px',
                      mb: '10px'
                    }}>
                    <ComplexContent
                      label='complexConcepts'
                      intelligibilityData={intelligibilityData}
                      noDataText='noComplexConcepts'
                      noDataIcon='textSlash'
                    />
                  </Box>
                </TabPanel>
              )}
            </Stack>
          </Scrollbar>
        </TabContext>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${theme.palette.gray.light}`,
          padding: '10px',
          pr: isIntelligibilitySidebar ? '10px' : '25px'
        }}>
        <PermissionChecker permissions={permissions.CONTENT_REPORT}>
          <DownloadPDFButton documentId={documentId} documentName={intelligibilityData.name} />
        </PermissionChecker>
      </Box>
    </Grid>
  );
};

DocumentMetrics.propTypes = {
  intelligibilityData: PropTypes.object,
  header: PropTypes.any,
  documentId: PropTypes.string,
  isIntelligibilitySidebar: PropTypes.bool
};

export default DocumentMetrics;
