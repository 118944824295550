import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { faTextSlash } from 'resources/fontawesome/regular/faTextSlash';

export const EmptyTable = ({ typeOfData }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  return (
    <Box
      sx={{
        paddingTop: '70px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
      }}
      data-testid='emptyTable'>
      <FontAwesomeIcon
        icon={faTextSlash}
        style={{
          height: '80px',
          width: '100px',
          color: theme.palette.gray.light
        }}
      />
      <Typography
        sx={{
          fontSize: theme.typography.pxToRem(14),
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          pt: '30px',
          color: theme.palette.gray.main
        }}>
        {t('noItemsAddedYet', { typeOfData })}
      </Typography>
    </Box>
  );
};

EmptyTable.propTypes = {
  typeOfData: PropTypes.string
};
