export const permissions = {
  BILLING_MANAGE: 'billing.manage',
  BILLING_VIEW: 'billing.view',
  CONTENT_EDIT: 'content.edit',
  CONTENT_MANAGE: 'content.manage',
  CONTENT_RECALCULATION: 'content.recalculation',
  CONTENT_REPORT: 'content.report',
  CONTENT_SUGGESTION_IMPACT_SCORE: 'content.suggestionImpactScore',
  CONTENT_TRACK_ML_REQUESTS: 'content.trackMLRequests',
  CONTENT_VIEW: 'content.view',
  EXCLUSIONS_MANAGE: 'exclusions.manage',
  EXCLUSIONS_VIEW: 'exclusions.view',
  FILTERING_MANAGE: 'filtering.manage',
  LABELS_MANAGE: 'labels.manage',
  LABELS_VIEW: 'labels.view',
  REPORTING_EXPORT: 'reporting.export',
  REPORTING_REFRESH: 'reporting.refresh',
  REPORTING_VIEW: 'reporting.view',
  REPORTING_MANAGE: 'reporting.manage',
  USERS_AUTH_PROVIDER: 'users.authProvider',
  USERS_MANAGE: 'users.manage',
  USERS_VIEW: 'users.view'
};

export const checkPermission = (assignedPermissions, neededPermissions, allNeeded) => {
  if (!assignedPermissions || assignedPermissions.length === 0) {
    return false;
  }

  if (!neededPermissions) {
    return true;
  }

  if (Array.isArray(neededPermissions)) {
    return allNeeded
      ? neededPermissions.every(perm => assignedPermissions.includes(perm))
      : neededPermissions.some(perm => assignedPermissions.includes(perm));
  }

  return assignedPermissions.includes(neededPermissions);
};
