import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { BroadcastChannel } from 'broadcast-channel';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import {
  auth,
  initializeMixpanel,
  FIGMA_KEY,
  mixpanel,
  toastMessage,
  userCurrentStatus
} from 'helpers';
import {
  figmaPluginService,
  labelService,
  loginService,
  tenantService,
  tokenService
} from 'services';

const ACTIVITY_CHECK_INTERVAL = 1000;
const DEFAULT_LOGOUT_INTERVAL = 120 * 60;
const LOGOUT_TIME_KEY = '_expiredTime';
export const PROVIDER_KEY = '_provider';
export const TOKEN_MANAGER_KEY = '_tokenManager';
export const SUCCESSFUL_VALIDATION = '_successfulValidation';
export const SSO_TENANT = 'ssoTenant';
const STATUS_CODE_401 = 'Request failed with status code 401';
const PING_ID = 'PingId';

export const EVENTS = ['click', 'keydown', 'scroll'];
let currentTenantId = '';

export const UserContext = createContext({
  user: null,
  initialized: false,
  featureFlags: false,
  comingFromMFALoginPage: false,
  labels: []
});

export const setCurrentTenantId = tenantId => {
  currentTenantId = tenantId;
};

export const getCurrentTenantId = () => {
  return currentTenantId;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [labels, setLabels] = useState([]);
  const [featureFlags, setFeatureFlags] = useState(false);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [isSetupAccount, setIsSetupAccount] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [comingFromMFALoginPage, setComingFromMFALoginPage] = useState(false);
  const [tokenReceived, setTokenReceived] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [tError] = useTranslation('error');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const logoutChannel = new BroadcastChannel('logout');
  const { pathname, search } = useLocation();
  const isSSOUser = tokenService?.getProvider() === 'PingId';
  const autoLogoutIntervalRef = useRef(null);

  // If the event listener(onAuthStateChanged) uses the state directly it will save the initial state and won't be able to access the current state
  // Use refs for the states in the event listener to access the current state
  const firebaseUserRef = useRef(firebaseUser);
  const isSetupAccountRef = useRef(isSetupAccount);

  const setFirebaseUserRefAndState = data => {
    firebaseUserRef.current = data;
    setFirebaseUser(data);
  };

  const setIsSetupAccountRefAndState = data => {
    isSetupAccountRef.current = data;
    setIsSetupAccount(data);
  };

  const { data: labelsData, refetch: refetchLabels } = useQuery(
    ['labels'],
    () => labelService.getLabels(),
    {
      enabled: !!user,
      onError: () => {
        signUserOut();
      }
    }
  );

  useEffect(() => {
    if (labelsData) {
      setLabels(labelsData.items);
    }
  }, [labelsData]);

  useEffect(() => {
    localStorage.setItem('pathname', pathname + search);
    logoutChannel.addEventListener('message', async () => {
      if (localStorage.getItem(SUCCESSFUL_VALIDATION)) {
        await signOutAsync();
      }
    });

    return () => {
      logoutChannel.close();
    };
  }, []);

  useEffect(() => {
    if (user) {
      tenantService.getFeatureFlags().then(response => {
        if (response) {
          setFeatureFlags(response.featureFlags);
          setInitialized(true);
        }
      });
    }

    const figmaKey = localStorage.getItem(FIGMA_KEY);
    if (user?.authProvider === PING_ID && figmaKey) {
      sendKeys.mutate(figmaKey);
    }
    if (user?.authProvider === PING_ID) {
      clearInterval(autoLogoutIntervalRef.current);
    }
  }, [user]);

  useEffect(() => {
    if (tokenReceived) {
      localStorage.setItem(SUCCESSFUL_VALIDATION, true);
      getUserData();
    }
  }, [tokenReceived]);

  const sendKeys = useMutation(id => figmaPluginService.setTokenForFigmaPlugin(id));

  useEffect(() => {
    if (isSSOUser) {
      if (!user) {
        if (tokenService.isPingUserLoggedIn()) {
          getUserData();
        } else if (searchParams.get('code')) {
          tokenService.getPingToken(searchParams.get('code'), setTokenReceived).catch(() => {
            navigate('sso/500');
            setInitialized(true);
          });
        } else {
          tokenService.getAuthEndpoint().then(res => (window.location = res.data));
        }
      }
    } else {
      const unsubscribe = onAuthStateChanged(auth, userAuth => {
        if (firebaseUserRef.current !== userAuth || !userAuth) {
          setFirebaseUserRefAndState(userAuth);

          if (userAuth && !isSetupAccountRef.current) {
            if (isLogoutIntervalExpired()) {
              signUserOut();
            } else {
              getUserData();
            }
          } else {
            clearUserData();
            setInitialized(true);
          }
        }
      });

      return unsubscribe;
    }
  }, []);

  const signUserOut = () => {
    if (isSSOUser) {
      clearUserData();
    } else {
      loginService.logOut();
      signOut(auth);
    }

    mixpanel.reset();
    localStorage.removeItem('isCollapse');
  };

  const signOutAsync = async () => {
    if (!isSSOUser) {
      await loginService.logOut();
      await signOut(auth);
      navigate('/signin');
    }

    mixpanel.reset();
    localStorage.removeItem('isCollapse');
  };

  const clearUserData = () => {
    if (isSSOUser) {
      setTokenReceived(false);
      localStorage.removeItem(TOKEN_MANAGER_KEY);
      localStorage.removeItem(PROVIDER_KEY);
      localStorage.removeItem(SSO_TENANT);
    }

    setUser(null);
    localStorage.removeItem(FIGMA_KEY);
    localStorage.removeItem(LOGOUT_TIME_KEY);
    localStorage.removeItem(SUCCESSFUL_VALIDATION);
    if (autoLogoutIntervalRef.current) {
      clearInterval(autoLogoutIntervalRef.current);
    }
    for (const i in EVENTS) {
      window.removeEventListener(EVENTS[i], resetTimeout);
    }
  };

  const resetTimeout = () => {
    setTimeout(() => {
      localStorage.setItem(
        LOGOUT_TIME_KEY,
        Date.now() +
          parseInt(window.REACT_APP_LOGOUT_INTERVAL || DEFAULT_LOGOUT_INTERVAL) * 60 * 1000
      );
    }, 300);
  };

  useEffect(() => {
    autoLogoutIntervalRef.current = setInterval(() => {
      if (isLogoutIntervalExpired() && localStorage.getItem(SUCCESSFUL_VALIDATION)) {
        localStorage.removeItem(SUCCESSFUL_VALIDATION);
        signUserOut();
      }
    }, ACTIVITY_CHECK_INTERVAL);

    return () => {
      if (autoLogoutIntervalRef.current) {
        clearInterval(autoLogoutIntervalRef.current);
      }
    };
  }, []);

  const isLogoutIntervalExpired = () => {
    const expiredTime = parseInt(localStorage.getItem(LOGOUT_TIME_KEY) || 0, 10);
    return expiredTime > 0 && expiredTime < Date.now();
  };

  const addEventListeners = () => {
    for (const i in EVENTS) {
      window.addEventListener(EVENTS[i], resetTimeout);
    }
  };

  const redirectDisabledUser = userStatus => {
    if (userStatus === userCurrentStatus.DEACTIVATED || userStatus === userCurrentStatus.LOCKED) {
      navigate('/');
      toastMessage(enqueueSnackbar, closeSnackbar, tError('DisabledUser'), 'error');
    }
  };

  const getUserData = () => {
    loginService
      .login()
      .then(resUser => {
        if (resUser?.data.userStatus === userCurrentStatus.ACTIVE) {
          const userData = resUser.data;
          const currentTenant = userData.tenants.find(x => x.isDefault);
          setCurrentTenantId(currentTenant.id);
          setUserPermissions(userData);
        } else {
          signUserOut();
          redirectDisabledUser(resUser.data.userStatus);
        }
      })
      .catch(err => {
        //For sso users when token expiries and request is made after that, exception with 401 code is thrown for login api, in this case sso users will be redirected to their sign in page
        if (err.message === STATUS_CODE_401 && localStorage.getItem(SSO_TENANT)) {
          navigate(`/sso/${localStorage.getItem(SSO_TENANT)}`);
        }
      });
  };

  const setUserPermissions = userData => {
    loginService.getUserPermissionDetails().then(resPermissions => {
      if (resPermissions) {
        userData.permissions = resPermissions.data;
        setUserClaims(userData);
      }
    });
  };

  const setUserClaims = userData => {
    if (userData.privacyPolicyAcknowledged) {
      initializeMixpanel(userData);
    }
    mixpanel.track('Login');
    setUser(userData);
    resetTimeout();
    addEventListeners();
  };

  const userProviderValues = useMemo(
    () => ({
      user,
      setUser,
      setIsSetupAccountRefAndState,
      initialized,
      featureFlags,
      comingFromMFALoginPage,
      setComingFromMFALoginPage,
      labels,
      refetchLabels,
      logoutChannel,
      signOutAsync,
      signUserOut
    }),
    [
      user,
      setUser,
      setIsSetupAccountRefAndState,
      initialized,
      featureFlags,
      comingFromMFALoginPage,
      setComingFromMFALoginPage,
      labels,
      refetchLabels,
      logoutChannel,
      signOutAsync,
      signUserOut
    ]
  );

  return <UserContext.Provider value={userProviderValues}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.any
};
