import React, { useContext, lazy } from 'react';
import { Navigate, useLocation, useParams, useRoutes } from 'react-router-dom';

import { checkPermission, FIGMA_KEY, permissions, lazyRetry } from 'helpers';
import { DashboardLayout } from 'layouts';
import {
  DocumentStateProvider,
  ReportingAndAnalyticsProvider,
  SUCCESSFUL_VALIDATION,
  UserContext
} from 'providers';

import { Loadable } from './Loadable';

const AccountSettingsPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/AccountSettingsPage/AccountSettingsPage')))
);

const DocumentEditorPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentEditorPage/DocumentEditorPage')))
);
const ComingSoonPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/ComingSoonPage')))
);
const RiskPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ReportingAndAnalytics/Risk/RiskPage')))
);
const IntelligibilityPage = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import('../containers/ReportingAndAnalytics/Intelligibility/IntelligibilityPage')
    )
  )
);
const ProductivityPage = Loadable(
  lazy(() =>
    lazyRetry(() => import('../containers/ReportingAndAnalytics/Productivity/ProductivityPage'))
  )
);
const DocumentCreatePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentCreatePage/DocumentCreatePage')))
);
const DocumentManagementPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/DocumentManagementPage/DocumentManagementPage')))
);
const ForgotPasswordPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/ForgotPasswordPage')))
);
const InternalServerErrorPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/InternalServerErrorPage')))
);
const LoginPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/LoginPage')))
);
const MFALoginPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/MFALoginPage')))
);
const MFARegisterPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/MFARegisterPage')))
);
const Page404 = Loadable(lazy(() => lazyRetry(() => import('../containers/ErrorPages/Page404'))));
const PrivacyPolicyPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/PrivacyPolicyPage')))
);
const ProfilePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ProfilePage/ProfilePage')))
);
const VersionHistoryPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/VersionHistoryPage/VersionHistoryPage')))
);
const RegisterPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/RegisterPage')))
);
const ResetPasswordPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/authentication/ResetPasswordPage')))
);
const UnderMaintenancePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/ErrorPages/UnderMaintenancePage')))
);
const UserDashboardPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/UserDashboardPage/UserDashboardPage')))
);
const StyleGuidePage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/StyleGuidePage/StyleGuidePage')))
);
const CustomExclusionsPage = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import('../containers/StyleGuidePage/Exclusions/CustomExclusionsPage/CustomExclusionsPage')
    )
  )
);

const StandardExclusionsPage = Loadable(
  lazy(() =>
    lazyRetry(() =>
      import(
        '../containers/StyleGuidePage/Exclusions/StandardExclusionsPage/StandardExclusionsPage'
      )
    )
  )
);

const FigmaAuthPage = Loadable(
  lazy(() => lazyRetry(() => import('../containers/FigmaAuthPage/FigmaAuthPage')))
);

const DocumentContentPage = () => {
  const { id } = useParams();
  return (
    <DocumentStateProvider documentId={Number(id)}>
      <DocumentEditorPage />
    </DocumentStateProvider>
  );
};

let previousPath = '/';

export const getPreviousPage = () => {
  return previousPath;
};

const checkAvailableSettingsRoutes = user => {
  if (checkPermission(user.permissions, permissions.USERS_VIEW)) {
    return '/settings/users';
  }
  if (checkPermission(user.permissions, permissions.LABELS_VIEW)) {
    return '/settings/labels';
  }
  if (checkPermission(user.permissions, permissions.BILLING_MANAGE)) {
    return '/settings/billing';
  }
  return '/';
};

export const checkSubRoutes = user => {
  const createRoute = (path, element, permissions = [], children = []) => ({
    path,
    element: permissions.every(permission => user.permissions?.includes(permission))
      ? element
      : null,
    children
  });

  return [
    createRoute('content/create', <DocumentCreatePage />, [
      permissions.CONTENT_VIEW,
      permissions.CONTENT_MANAGE
    ]),
    createRoute('content', <DocumentManagementPage />, [permissions.CONTENT_VIEW]),
    createRoute('/dashboard', <UserDashboardPage />, [permissions.CONTENT_VIEW]),
    createRoute(
      'reporting/risk',
      <ReportingAndAnalyticsProvider>
        <RiskPage />
      </ReportingAndAnalyticsProvider>,
      [permissions.REPORTING_VIEW]
    ),
    createRoute(
      'reporting/intelligibility',
      <ReportingAndAnalyticsProvider>
        <IntelligibilityPage />
      </ReportingAndAnalyticsProvider>,
      [permissions.REPORTING_VIEW]
    ),
    createRoute(
      'reporting/productivity',
      <ReportingAndAnalyticsProvider>
        <ProductivityPage />
      </ReportingAndAnalyticsProvider>,
      [permissions.REPORTING_VIEW, permissions.USERS_VIEW]
    ),
    createRoute('settings/users', <AccountSettingsPage />, [permissions.USERS_VIEW]),
    createRoute('settings/labels', <AccountSettingsPage />, [permissions.LABELS_VIEW]),
    createRoute('settings/billing', <AccountSettingsPage />, [permissions.BILLING_MANAGE]),
    createRoute('/settings', <Navigate to={checkAvailableSettingsRoutes(user)} replace />),
    createRoute('settings/users/invite', <AccountSettingsPage />, [permissions.USERS_MANAGE]),
    createRoute('settings/users/:id', <AccountSettingsPage />, [permissions.USERS_MANAGE]),
    createRoute(
      'governance',
      <StyleGuidePage />,
      [permissions.EXCLUSIONS_VIEW],
      [
        {
          index: true,
          element: user.permissions?.includes(permissions.EXCLUSIONS_VIEW) ? (
            <Navigate to='exclusions/standard' replace />
          ) : null
        },
        createRoute('exclusions/custom', <CustomExclusionsPage />, [permissions.EXCLUSIONS_VIEW]),
        createRoute('exclusions/standard', <StandardExclusionsPage />, [
          permissions.EXCLUSIONS_VIEW
        ])
      ]
    ),
    createRoute('content/:id', <DocumentContentPage />, [permissions.CONTENT_VIEW]),
    createRoute('content/:id/history', <VersionHistoryPage />, [permissions.CONTENT_VIEW]),
    createRoute('profile', <ProfilePage />),
    createRoute('/figma', <FigmaAuthPage />),
    createRoute('/reporting', <Navigate to={'risk'} replace />),
    createRoute('/', <Navigate to={'dashboard'} replace />),
    createRoute('*', <Navigate to={'dashboard'} replace />)
  ];
};

export const Router = () => {
  const { user } = useContext(UserContext);
  const path = useLocation();
  const localStoragePathname = localStorage.getItem('pathname');

  // when signed in, return user to the last visited page before sign out
  if (!user && path.pathname !== '/signin') {
    previousPath = localStoragePathname ?? path.pathname;
    localStorage.removeItem('pathname');
  }

  const isMFACodeValid = localStorage.getItem(SUCCESSFUL_VALIDATION);

  let routes = [];

  if (user && isMFACodeValid) {
    if (user.privacyPolicyAcknowledged) {
      let subroutes = checkSubRoutes(user);
      subroutes = subroutes.filter(sr => !!sr);

      routes = [
        { path: '404', element: <Page404 /> },
        { path: '500', element: <InternalServerErrorPage /> },
        { path: 'coming_soon', element: <ComingSoonPage /> },
        { path: 'under_maintenance', element: <UnderMaintenancePage /> },
        { path: '/', element: <DashboardLayout />, children: subroutes }
      ];
    } else {
      routes = [
        { path: 'privacy_policy', element: <PrivacyPolicyPage /> },
        { path: '*', element: <Navigate to={'privacy_policy'} replace /> }
      ];
    }
  }

  const loggedOutRoutes = [
    { path: 'signin', element: <LoginPage /> },
    { path: 'setup_account', element: <RegisterPage /> },
    { path: 'reset_password', element: <ResetPasswordPage /> },
    { path: 'forgot_password', element: <ForgotPasswordPage /> },
    { path: '404', element: <Page404 /> },
    { path: '500', element: <InternalServerErrorPage /> },
    { path: 'sso/500', element: <InternalServerErrorPage hideButton={true} /> },
    { path: 'coming_soon', element: <ComingSoonPage /> },
    { path: 'under_maintenance', element: <UnderMaintenancePage /> },
    { path: '/', element: <Navigate to={'signin'} replace /> }
  ];

  if (user && !isMFACodeValid) {
    routes = loggedOutRoutes.concat(
      { path: 'mfa_login', element: <MFALoginPage /> },
      !user.mfaCode && { path: 'mfa_register', element: <MFARegisterPage /> },
      {
        path: '*',
        element: <Navigate to={user.mfaCode ? '/mfa_login' : '/mfa_register'} replace />
      }
    );
  }

  if (!user) {
    if (path.pathname === '/figma') {
      const searchParam = path.search;
      const figmaKey = searchParam.replace('?id=', '');
      localStorage.setItem(FIGMA_KEY, figmaKey);
    }
    routes = loggedOutRoutes.concat({
      path: '*',
      element: <Navigate to={'signin'} replace />
    });
  }

  return useRoutes(routes);
};
