import React, { forwardRef } from 'react';

import { MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { FormField } from './FormField';

const useStyles = makeStyles({
  placeholder: props => ({
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    color: props.palette.gray[600],
    fontSize: props.typography.pxToRem(14)
  }),
  menuItem: props => ({
    height: '40px',
    fontFamily: props.typography.fontFamilyPrimaryRegular,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.gray.dark,
    '&:hover': {
      backgroundColor: props.palette.gray[50]
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent !important'
    }
  }),
  dropdownPaper: {
    marginTop: '5px',
    boxShadow: `0px 0px 10px 0px ${'rgba(99, 115, 129, 0.3)'}`
  }
});

export const FormFieldDropdown = forwardRef(
  ({ menuItemData, errors, dataCyAttributes, onChange, placeholder, value, variant }, ref) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
      <Stack ref={ref} sx={{ maxHeight: '50px' }} data-testid='formFieldDropdown'>
        <FormField
          select
          variant={variant}
          error={errors && Boolean(errors)}
          onChange={onChange}
          SelectProps={{
            displayEmpty: true,
            renderValue: () => value || <span className={classes.placeholder}>{placeholder}</span>,
            SelectDisplayProps: {
              'data-cy': dataCyAttributes?.dropdown
            },
            MenuProps: {
              PaperProps: {
                className: classes.dropdownPaper
              }
            }
          }}>
          {menuItemData?.map(item => (
            <MenuItem
              disableRipple
              key={item.id}
              value={item.id}
              className={classes.menuItem}
              data-cy={dataCyAttributes?.item + item.id}>
              {item.name}
            </MenuItem>
          ))}
        </FormField>
      </Stack>
    );
  }
);

FormFieldDropdown.displayName = 'FormFieldDropdown';

FormFieldDropdown.propTypes = {
  menuItemData: PropTypes.array,
  errors: PropTypes.any,
  dataCyAttributes: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string
};
