import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { authPages } from 'helpers';

import { useStyles } from './AuthFormsStyle.css';

export const AuthTitle = ({ page, pageTitle, pageDescription }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [t] = useTranslation('common');

  switch (page) {
    case authPages.login:
      pageTitle = t('welcomeBack');
      pageDescription = t('enterDetails');
      break;
    case authPages.register:
      pageTitle = t('manageTheJobMoreEffectively');
      pageDescription = t('fillOutYouDetails');
      break;
    case authPages.forgotPassword:
      pageTitle = t('forgotPasswordHeading');
      pageDescription = t('pleaseEnterTheEmailAddress');
      break;
    case authPages.resetPassword:
      pageTitle = t('resetYourPassword');
      pageDescription = '';
      break;
    case authPages.mfaRegister:
      pageTitle = t('authenticateYourAccount');
      pageDescription = '';
      break;
    case authPages.mfaLogin:
      pageTitle = t('signInAuthentication');
      pageDescription = '';
      break;
  }

  return (
    <div>
      <Typography variant='h3' gutterBottom className={classes.title}>
        {pageTitle}
      </Typography>
      {pageDescription !== '' && (
        <Typography className={classes.description}>{pageDescription}</Typography>
      )}
    </div>
  );
};

AuthTitle.propTypes = {
  page: PropTypes.string,
  pageDescription: PropTypes.string,
  pageTitle: PropTypes.string
};
