import React from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import {
  ADVERBS,
  PASSIVE_VOICE,
  READING_TIME,
  FLESCH_KINKAID,
  PARAGRAPH_LENGTH,
  SENTENCE_LENGTH,
  SYLLABLES_PER_WORD
} from '../../helpers/enums';
import { useStyles } from '../IntelligibilitySidebar/IntelligibilitySidebar.css';
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation';
import { MetricsRating } from '../MetricsRating/MetricsRating';

export const TextDensityMetrics = ({ intelligibilityData, isLoading }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);

  const formatReadingTime = minutes => {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = Math.ceil(minutes % 60);
      return remainingMinutes === 0 ? hours + 'h ' : hours + 'h ' + remainingMinutes + 'm';
    } else {
      return Math.ceil(minutes) + 'm';
    }
  };

  return (
    <>
      <MetricsRating
        metricType={FLESCH_KINKAID}
        textMetric={intelligibilityData.textMetrics.fkre}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={SYLLABLES_PER_WORD}
        textMetric={intelligibilityData.textMetrics.syllablesPerWord}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={SENTENCE_LENGTH}
        textMetric={intelligibilityData.textMetrics.averageWordsPerSentence}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={PARAGRAPH_LENGTH}
        textMetric={intelligibilityData.textMetrics.averageSentencesPerParagraph}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={READING_TIME}
        textMetric={{
          value: formatReadingTime(intelligibilityData.textMetrics.readingTime)
        }}
        showTooltips={false}
        showScoreIcon={false}
        isLoading={isLoading}
      />
      <Divider sx={{ m: '15px 0px' }} />
      <Typography variant='body2' className={classes.headerStyle}>
        {t('writingStyle')}
      </Typography>
      <MetricsRating
        metricType={PASSIVE_VOICE}
        textMetric={intelligibilityData.textMetrics.passiveVoiceCount}
        isLoading={isLoading}
      />
      <MetricsRating
        metricType={ADVERBS}
        textMetric={intelligibilityData.textMetrics.adverbsCount}
        isLoading={isLoading}
      />
      {isLoading && (
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', pt: '50px' }}>
          <LoadingAnimation height={60} width={60} marginRight='5px' />
          <Typography
            sx={{
              mt: '10px',
              color: theme.palette.gray.main,
              fontFamily: theme.typography.fontFamilySecondaryMedium,
              fontSize: theme.typography.pxToRem(12)
            }}>
            {t('loadingResults')}
          </Typography>
        </Stack>
      )}
    </>
  );
};

TextDensityMetrics.propTypes = {
  intelligibilityData: PropTypes.object,
  isLoading: PropTypes.bool
};
