import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { MyAvatar, LightTooltip } from 'components';
import { userSeatString } from 'helpers/enums';
import { UserContext } from 'providers';
import { faChevronDown } from 'resources/fontawesome/solid/faChevronDown';
import { APP_PATHS } from 'routes';

export const AccountSettings = ({ isCollapse, setIsSwitchAccountDialogOpen }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { user } = useContext(UserContext);

  return (
    <Stack
      data-testid='accountSettings'
      sx={{
        flexDirection: 'row',
        alignItems: 'flex-start',
        mb: '5px'
      }}>
      <LightTooltip
        title={isCollapse && t('accountPage')}
        placement='right'
        data-testid='lightTooltip'>
        <Link underline='none' component={RouterLink} to={APP_PATHS.PROFILE} sx={{ pl: 2 }}>
          <MyAvatar
            displayName={user.displayName}
            sx={{ width: 40, height: 40, fontSize: theme.typography.pxToRem(18) }}
          />
        </Link>
      </LightTooltip>
      <Stack
        sx={{
          pl: 2
        }}>
        <Stack
          sx={{
            alignItems: 'flex-start',
            flexDirection: 'row'
          }}>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(12),
              lineHeight: '16px',
              maxWidth: '130px'
            }}>
            {user.displayName}
          </Typography>
          {user.tenants.length > 1 && (
            <Box sx={{ display: 'flex', px: 1 }}>
              <LightTooltip title={t('switchAccount')} placement='right'>
                <IconButton
                  data-testid='switchAccountButton'
                  disableRipple
                  sx={{ p: 0 }}
                  onClick={() => setIsSwitchAccountDialogOpen(true)}>
                  <FontAwesomeIcon icon={faChevronDown} style={{ width: '10px', height: '10px' }} />
                </IconButton>
              </LightTooltip>
            </Box>
          )}
        </Stack>
        <Typography
          sx={{
            color: theme.palette.gray.dark,
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(12),
            textWrap: 'nowrap'
          }}>
          {user?.tenants.find(t => t.isDefault).name}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.gray.main,
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(12),
            textWrap: 'nowrap'
          }}>
          {t(userSeatString[user?.seat])}
        </Typography>
      </Stack>
    </Stack>
  );
};

AccountSettings.propTypes = {
  isCollapse: PropTypes.bool,
  setIsSwitchAccountDialogOpen: PropTypes.func
};
