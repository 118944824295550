import axios from 'axios';
import queryString from 'query-string';

export const download = res => {
  fetch(res.fileAbsoluteUrl + res.sas).then(function (t) {
    return t.blob().then(b => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', res.fileName);
      a.click();
    });
  });
};

export const downloadCSV = (res, fileName) => {
  const a = document.createElement('a');
  a.href = 'data:attachment/csv,' + encodeURIComponent(res.data);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return res;
};

export const downloadFile = (axios, url, fileName, fileExtension, versionName, headers = null) => {
  const documentName = versionName ? `${fileName} - ${versionName} ` : fileName;
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob',
    headers: headers
  }).then(response => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute('download', documentName + fileExtension);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const downloadPDFReport = async (baseUrl, dateRange, reportData, pdfName) => {
  const dateQueryParams = {
    startDate: dateRange?.startDate,
    endDate: dateRange?.endDate
  };

  const dateQuery = queryString.stringify(dateQueryParams, {
    skipNull: true,
    skipEmptyString: true
  });

  const url = `${baseUrl}/download?${dateQuery}`;
  const response = await axios.post(url, reportData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const a = document.createElement('a');
  const blob = new Blob([response.data]);
  a.href = window.URL.createObjectURL(blob);
  a.setAttribute('download', pdfName);
  a.click();
};
