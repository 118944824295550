import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link as RouterLink, useSearchParams, useNavigate } from 'react-router-dom';

import { Alert, Box, Link, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  indexedDBLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { Icons, PrimaryButton, TextFieldStyle } from 'components';
import {
  auth,
  handleFirebaseErrors,
  handleShowPassword,
  SINGLE_SIGN_ON,
  toastMessage,
  FIGMA_KEY
} from 'helpers';
import { UserContext } from 'providers';
import { loginService } from 'services';

import { useStyles } from './AuthFormsStyle.css';
import { AuthTitle } from './AuthTitle';
import { AUTH_PATHS } from '../../routes/paths';

const NUMBER_OF_LOGIN_ATTEMPTS = 'loginAttempts';
const WRONG_PASSWORD = 'auth/wrong-password';

export const LoginForm = () => {
  const [t] = useTranslation('common');
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, comingFromMFALoginPage, setComingFromMFALoginPage, signOutAsync } =
    useContext(UserContext);
  const [searchParams] = useSearchParams();
  const figmaKey = searchParams.get('id');
  const sso = searchParams.get('sso');

  useEffect(() => {
    if (figmaKey) {
      localStorage.setItem(FIGMA_KEY, figmaKey);
    }
  }, []);

  const loginSchema = Yup.object().shape({
    password:
      sso === SINGLE_SIGN_ON
        ? null
        : Yup.string().min(8, t('tooShort')).max(50, t('tooLong')).required(t('required')),
    email: Yup.string().email(t('invalidEmail')).required(t('required'))
  });

  const wrongPasswordCounter = async (email, setErrors) => {
    let storedValue = Number(localStorage.getItem(NUMBER_OF_LOGIN_ATTEMPTS));
    if (storedValue === 2) {
      await loginService.lockUser(email).then(res => {
        if (res.status === 200) {
          setErrors({ afterSubmit: t('userAccountDisabledByWrongPassword') });
          localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, 0);
        }
      });
      return;
    }
    localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, ++storedValue);
  };

  const getSsoUrl = useMutation(({ email }) => loginService.getSsoUrl(email), {
    onSuccess: (res, variables) => {
      const { setSubmitting } = variables;
      if (res.status === 200) {
        window.open(res.data);
      }
      if (res.status === 204) {
        toastMessage(enqueueSnackbar, closeSnackbar, t('emailForSSORequired'), 'success');
      }
      setSubmitting(false);
    },
    onError: (res, variables) => {
      const { setSubmitting } = variables;
      setSubmitting(false);
    }
  });

  const handleLogout = async () => {
    try {
      await signOutAsync();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(t('unableToLogout'), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (user && comingFromMFALoginPage) {
      handleLogout();
    }
    if (user && !comingFromMFALoginPage) {
      if (user.mfaCode) {
        navigate(AUTH_PATHS.MFA_LOGIN);
      } else {
        navigate(AUTH_PATHS.MFA_REGISTER);
      }
    }
    setComingFromMFALoginPage(false);
  }, [user]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      await setPersistence(auth, indexedDBLocalPersistence);
      await signInWithEmailAndPassword(auth, values.email, values.password);
      localStorage.setItem(NUMBER_OF_LOGIN_ATTEMPTS, 0);
    } catch (error) {
      error.code === WRONG_PASSWORD && wrongPasswordCounter(values.email, setErrors);
      const message = handleFirebaseErrors(error);
      setErrors({ afterSubmit: t(message) });
    }
    setSubmitting(false);
  };

  const submitSSOEmail = (values, { setSubmitting }) => {
    getSsoUrl.mutate({ email: values.email, setSubmitting });
  };

  const isSSOUser = sso === SINGLE_SIGN_ON;

  const handleSignIn = () => {
    const baseUrl = `/signin?id=${localStorage.getItem(FIGMA_KEY)}`;
    navigate(`${baseUrl}&sso=${SINGLE_SIGN_ON}`);
  };

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <AuthTitle page='login' />
      </Box>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginSchema}
        onSubmit={isSSOUser ? submitSSOEmail : handleSubmit}>
        {({ isSubmitting, errors, touched, getFieldProps }) => (
          <Form autoComplete='off' noValidate>
            <Stack spacing={3} sx={{ alignItems: 'center', mt: 5 }}>
              {errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}
              <TextFieldStyle
                className={classes.textFieldStyle}
                autoComplete='username'
                type='email'
                inputname={t('emailAddress')}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        marginRight: '20px',
                        borderRadius: 'initial',
                        border: 'transparent',
                        marginTop: '5px'
                      }}>
                      <Icons iconName={'envelopeIcon'} />
                    </div>
                  )
                }}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                placeholder={t('email')}
                helperText={touched.email && errors.email}
                data-testid='email'
                dataCy='emailInput'
              />
              {!isSSOUser && (
                <>
                  <TextFieldStyle
                    className={classes.textFieldStyle}
                    autoComplete='current-password'
                    type={showPassword ? 'text' : 'password'}
                    inputname={t('password')}
                    InputProps={{
                      startAdornment: (
                        <div
                          style={{
                            marginRight: '20px',
                            borderRadius: 'initial',
                            border: 'transparent',
                            marginTop: '5px'
                          }}>
                          <Icons iconName={'padlockIcon'} />
                        </div>
                      ),
                      endAdornment: handleShowPassword(showPassword, setShowPassword)
                    }}
                    placeholder={t('password')}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    data-testid='password'
                    dataCy='passwordInput'
                  />
                  <Link
                    component={RouterLink}
                    to={AUTH_PATHS.FORGOT_PASSWORD}
                    sx={{
                      width: '340px',
                      fontFamily: theme.typography.fontFamilyPrimaryMedium,
                      color: theme.palette.quaternary.main,
                      fontSize: theme.typography.pxToRem(14),
                      textDecoration: 'none',
                      '&:hover': { textDecoration: 'underline' },
                      userSelect: 'none'
                    }}>
                    {t('forgotPasswordLink')}
                  </Link>
                </>
              )}
              <PrimaryButton
                type={'submit'}
                loading={isSubmitting}
                buttonText={'login'}
                width={'340px'}
                dataCy='loginButton'
                dataTestId='loginButton'
              />
              {!isSSOUser && (
                <Link
                  onClick={handleSignIn}
                  component='button'
                  sx={{
                    fontFamily: theme.typography.fontFamilySecondaryMedium,
                    color: theme.palette.longSentence?.main,
                    fontSize: theme.typography.pxToRem(16),
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'underline' },
                    userSelect: 'none',
                    mt: '30px'
                  }}>
                  {t(SINGLE_SIGN_ON)}
                </Link>
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
