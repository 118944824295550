export const featureFlagsEnum = {
  COMMENTING: 'Commenting',
  COMPLEX_CONCEPTS_DISPLAY: 'ComplexConceptsDisplay',
  DOCUMENT_MANAGEMENT_PINNED_HEADER: 'DocumentManagementPinnedHeader',
  EXPANDED_EXCLUSION_CONFIGURATION: 'ExpandedExclusionConfiguration',
  PERSONAS_SCORING: 'PersonasScoring',
  UPLOAD_DOCUMENT_FROM_URL: 'UploadDocumentFromUrl',
  DOCUMENT_EDITOR: 'DocumentEditor',
  PDF_UPLOAD: 'UploadPdf',
  HTML_UPLOAD: 'UploadHtml',
  SEAT_PERMISSIONS: 'SeatPermissions',
  SHOW_SUGGESTIONS_IN_FIGMA_PLUGIN: 'ShowSuggestionsInFigmaPlugin'
};

export const checkFeatureFlag = (assignedFeatureFlags, neededFeatureFlags) => {
  if (
    !neededFeatureFlags ||
    !assignedFeatureFlags ||
    assignedFeatureFlags.length === 0 ||
    !assignedFeatureFlags[neededFeatureFlags]
  ) {
    return false;
  }

  return assignedFeatureFlags[neededFeatureFlags];
};
