import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomTooltip, Icons } from 'components';

import { improvementPercentage } from '../../helpers/methods';

export const MetricsRating = ({
  metricType,
  textMetric,
  writingStyle,
  isKeyIssues = false,
  showTooltips = true,
  showScoreIcon = true,
  isLoading,
  isNeutral = false
}) => {
  const theme = useTheme();
  const [t] = useTranslation('common');

  let improvementColor;
  if (isNeutral) {
    improvementColor = theme.palette.common.black;
  } else if (textMetric?.isPositiveImprovement) {
    improvementColor = theme.palette.active.dark;
  } else {
    improvementColor = theme.palette.error.main;
  }

  const tooltipThresholdContent = () => {
    return (
      <>
        {textMetric.thresholds.map((threshold, index, array) => (
          <Stack
            key={metricType + '_' + textMetric.value}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              mt: '5px',
              mb: array.length === index + 1 ? '0px' : '5px'
            }}>
            <Icons iconName={threshold.label + 'ScoreIcon'} />
            <Typography
              sx={{
                ml: '10px',
                fontFamily: theme.typography.fontFamilyPrimaryRegular,
                fontSize: theme.typography.pxToRem(14)
              }}>
              {threshold.displayValue}
            </Typography>
          </Stack>
        ))}
      </>
    );
  };

  const tooltipContent = () => {
    return (
      <Grid sx={{ display: 'flex', direction: 'column', alignItems: 'center' }}>
        {!isKeyIssues && <Icons iconName={'goodScoreIcon'} />}
        <Typography
          sx={{
            ml: '10px',
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(14)
          }}>
          {writingStyle}
        </Typography>
      </Grid>
    );
  };

  const getIconName = () => {
    if (isKeyIssues) {
      return 'circleExclamation';
    }

    if (isNeutral) {
      return 'neutralScoreIcon';
    }

    return textMetric.status ? textMetric.status + 'ScoreIcon' : 'badScoreIcon';
  };

  return (
    <Grid
      sx={{
        display: 'flex',
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: '15px'
      }}>
      <Grid sx={{ display: 'flex', alignItems: 'center' }}>
        {showScoreIcon && (
          <Icons
            iconName={getIconName()}
            height={'16'}
            width={'16'}
            fill={isKeyIssues ? theme.palette['intelligibility_Level5'].dark : undefined}
          />
        )}
        <Typography
          data-testid='metricType'
          variant='body2'
          sx={{
            ml: showScoreIcon ? '10px' : '0px',
            mr: '10px',
            color: isKeyIssues ? theme.palette.error.main : theme.palette.gray.dark,
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            fontSize: theme.typography.pxToRem(12)
          }}>
          {t(metricType)}
        </Typography>
        {showTooltips && (
          <CustomTooltip
            dataTestId='tooltip'
            tooltipContent={writingStyle ? tooltipContent() : tooltipThresholdContent()}
          />
        )}
      </Grid>
      {!isKeyIssues && (
        <Grid data-testid='textMetricValue' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant='body2'
            sx={{
              lineHeight: 'normal',
              color: theme.palette.gray.dark,
              fontFamily: theme.typography.fontFamilyPrimaryMedium,
              fontSize: theme.typography.pxToRem(14)
            }}>
            {isLoading ? '-' : textMetric.value}
          </Typography>
          {textMetric?.improvementPercentage !== undefined &&
            textMetric?.improvementPercentage !== 0 && (
              <Typography
                data-testid='textMetricImprovementPercentage'
                variant='body2'
                sx={{
                  alignSelf: 'flex-end',
                  mb: '2px',
                  ml: '5px',
                  color: improvementColor,
                  fontFamily: theme.typography.fontFamilyPrimaryMedium,
                  fontSize: theme.typography.pxToRem(10)
                }}>
                {improvementPercentage(textMetric?.improvementPercentage)}%
              </Typography>
            )}
        </Grid>
      )}
    </Grid>
  );
};

MetricsRating.propTypes = {
  metricType: PropTypes.string,
  textMetric: PropTypes.object,
  writingStyle: PropTypes.string,
  isKeyIssues: PropTypes.bool,
  showTooltips: PropTypes.bool,
  showScoreIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNeutral: PropTypes.bool
};
