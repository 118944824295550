import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { getCurrentTenantId } from 'providers';
import { tokenService } from 'services';

const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_API_KEY || 'mock_key',
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

//axios interceptors
axios.interceptors.request.use(
  async config => {
    const isRequestFromFigma =
      config.url.includes('/get_figma_keys') || config.url.includes('/figma/download_report');

    if (!isRequestFromFigma) {
      config.headers['TenantId'] = getCurrentTenantId();
      if (
        config.headers['x-ms-blob-type'] ||
        config.url.includes('/reset_password') ||
        config.url.includes('/user_activation') ||
        config.url.includes('/user_disable_multiple_login_fail') ||
        config.url.includes('/token') ||
        config.url.includes('/auth_endpoint') ||
        config.url.includes('/get_figma_token') ||
        config.url.includes('/sso_endpoint')
      ) {
        return config;
      }
    }
    const accessToken = await tokenService.getToken(isRequestFromFigma);

    if (accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken;
    }

    return config;
  },
  err => {
    return Promise.reject(new Error(err));
  }
);

export const getApiCoreUrl = () => {
  const r = new RegExp('^(?:[a-z]+:)?//', 'i');

  if (r.test(window.REACT_APP_CORE_API_URL)) {
    return window.REACT_APP_CORE_API_URL;
  } else {
    return window.location.origin + window.REACT_APP_CORE_API_URL;
  }
};

export const appVersion = window.REACT_APP_VERSION;
export const mixpanelProjectId = window.REACT_APP_MIXPANEL_PROJECT_ID;
export const mixpanelUri = window.REACT_APP_MIXPANEL_URI;
export const sentryDSN = window.REACT_APP_SENTRY_DSN;
export const sentryEnvironment = window.REACT_APP_SENTRY_ENVIRONMENT;
export const sentrySampleRate = Number(window.REACT_APP_SENTRY_SAMPLE_RATE);
