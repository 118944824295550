import React from 'react';

import { Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Icons } from 'components';

export const AddLabelButton = ({ setIsOpen, isOpen, title, isLabelList = false }) => {
  const theme = useTheme();

  return (
    <Grid container direction='row' sx={{ alignItems: 'center' }}>
      <Grid
        item
        sx={{
          width: 30,
          height: 30,
          borderRadius: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mr: '15px',
          ml: isLabelList && '10px'
        }}>
        <IconButton
          data-testid='addLabelButton'
          onClick={() => setIsOpen(true)}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
          <Icons
            iconName={isLabelList ? 'createLabelIcon' : 'greenPlus'}
            fill={isOpen ? theme.palette.gray.lightest : theme.palette.primary.main}
          />
        </IconButton>
      </Grid>
      <Typography
        data-cy='addLabelButtonText'
        sx={{
          fontFamily: theme.typography.fontFamilySecondaryMedium,
          color: isOpen ? theme.palette.gray.light : theme.palette.primary.main,
          cursor: 'pointer',
          marginLeft: isLabelList && '10px'
        }}
        onClick={() => setIsOpen(true)}>
        {title}
      </Typography>
    </Grid>
  );
};

AddLabelButton.propTypes = {
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  isLabelList: PropTypes.bool,
  isOpen: PropTypes.bool
};
