import React from 'react';
import { useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useStyles } from './IntelligibilitySidebar.css';

export const ImprovementInfo = ({ title, description, value, circleColor, isPercentage }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <>
      <Typography
        sx={{
          color: theme.palette.gray.main,
          fontFamily: theme.typography.fontFamilyPrimaryRegular,
          fontSize: theme.typography.pxToRem(14),
          my: '15px'
        }}>
        {t(title)}
      </Typography>
      <Stack
        sx={{
          flexDirection: isPercentage ? 'column' : 'row',
          alignItems: isPercentage ? 'flex-start' : 'center'
        }}>
        <Typography
          sx={{
            color: theme.palette.gray.dark,
            fontFamily: theme.typography.fontFamilySecondaryMedium,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightSemiMedium
          }}>
          {value}
        </Typography>
        {isPercentage && (
          <Stack direction='row'>
            <Typography
              variant='body2'
              sx={{
                color: theme.palette.gray.dark,
                fontFamily: theme.typography.fontFamilyPrimaryRegular
              }}>
              {`${t('from')} `}
              {description}
            </Typography>
            <Typography variant='body2' className={classes.textStyle} sx={{ ml: '5px' }}>
              {t('changesMade', { count: description })}
            </Typography>
          </Stack>
        )}
        {!isPercentage && (
          <>
            <CircleIcon
              sx={{
                width: '10px',
                height: '10px',
                ml: '10px',
                color: theme.palette[circleColor]?.dark
              }}
            />
            <Typography
              variant='body2'
              sx={{
                ml: '10px',
                fontFamily: theme.typography.fontFamilySecondaryMedium,
                color: theme.palette.gray.dark
              }}>
              {t('intelligibility_level_' + description)}
            </Typography>
          </>
        )}
      </Stack>
    </>
  );
};

ImprovementInfo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.number,
  circleColor: PropTypes.string,
  isPercentage: PropTypes.bool
};
