import React from 'react';
import { useTranslation } from 'react-i18next';

import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { CustomTooltip } from 'components';

import { useStyles } from './IntelligibilitySidebar.css';

export const PersonasScoring = ({ intelligibilityData }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div data-testid='personasScoring'>
      <Stack direction={'row'} gap='10px'>
        <Typography className={classes.headerStyle}>{t('scoringForPersonas')}</Typography>
        <CustomTooltip tooltipContent={t('personasDescription')} />
      </Stack>
      <Stack direction='column' sx={{ justifyContent: 'space-between' }}>
        {intelligibilityData?.personas?.map(persona => {
          return (
            <Stack
              key={persona.id}
              direction='row'
              sx={{ justifyContent: 'space-between', marginTop: '15px' }}>
              <Stack sx={{ flexDirection: 'row', gap: '10px' }}>
                <Typography
                  sx={{
                    fontSize: theme.typography.pxToRem(12),
                    fontFamily: theme.typography.fontFamilyPrimaryRegular,
                    color: theme.palette.primary.main
                  }}>
                  {persona.name}
                </Typography>
                <CustomTooltip tooltipContent={persona.description} />
              </Stack>
              <Stack sx={{ flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                <CircleIcon
                  sx={{
                    width: '8px',
                    height: '8px',
                    color:
                      theme.palette[`intelligibility_Level${persona.intelligibilityLevel}`].dark
                  }}
                />
                <Typography
                  sx={{
                    fontSize: theme.typography.pxToRem(14),
                    fontFamily: theme.typography.fontFamilyPrimaryRegular,
                    color: theme.palette.primary.main
                  }}>
                  {t('intelligibility_level_' + persona.intelligibilityLevel)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </div>
  );
};

PersonasScoring.propTypes = {
  intelligibilityData: PropTypes.object
};
