import Quill from 'quill';

const Inline = Quill.import('blots/inline');

export class ExclusionBlot extends Inline {
  static blotName = 'excluded_text';

  static tagName = 'excluded_text';

  static create() {
    const node = super.create();
    node.classList.add('excluded_text');
    return node;
  }
}

Quill.register('formats/excluded_text', ExclusionBlot);
