import React, { useEffect, createContext, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as signalR from '@microsoft/signalr';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import {
  checkRequestLoggingToConsole,
  getApiCoreUrl,
  throttle,
  toastMessage,
  MESSAGE_TYPES
} from 'helpers';
import { getCurrentTenantId, UserContext } from 'providers';
import { tokenService } from 'services';
import { createEditorStore } from 'stores';

const autosaveIntervalDuration = parseInt(window.REACT_APP_SAVE_INTERVAL || 30) * 1000;
const rescoreIntervalDuration = parseInt(window.REACT_APP_RESCORE_INTERVAL || 2) * 1000;

export const DocumentStateContext = createContext({
  connection: null,
  editorStore: null
});

export const DocumentStateProvider = ({ documentId, children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [t] = useTranslation('common');
  const [tError] = useTranslation('error');

  const editorStore = useRef(createEditorStore(documentId)).current;

  const [connection, setConnection] = useState(null);
  const connectionRef = useRef(null);
  connectionRef.current = connection;
  const reconnectionAttemptsLimitReached = useRef(false);
  const autosaveTimer = useRef(null);
  const rescoreTimer = useRef(null);

  const { user: currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!documentId) {
      return;
    }

    tokenService.getToken().then(token => {
      const currentTenantId = getCurrentTenantId();
      const conn = new signalR.HubConnectionBuilder()
        .withUrl(
          `${getApiCoreUrl()}/suggestionDensityHub?access_token=${token}&document_id=${documentId}&tenant_id=${currentTenantId}`,
          {
            withCredentials: false
          }
        )
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            if (retryContext.previousRetryCount > 100) {
              reconnectionAttemptsLimitReached.current = true;
              return null;
            }
            if (retryContext.previousRetryCount < 5) {
              retryContext.previousRetryCount += 1;
              return retryContext.previousRetryCount * 1000;
            }
            return 15000;
          }
        })
        .withServerTimeout(3000)
        .withKeepAliveInterval(1000)
        .build();

      conn.onreconnecting(() => {
        editorStore.getState().setIsReconnecting(true);
        toastMessage(enqueueSnackbar, closeSnackbar, t('reconnecting'), 'warning');
      });

      conn.onreconnected(() => {
        joinGroup(true);
      });

      conn.onclose(async () => {
        reconnectionAttemptsLimitReached.current && (await startConnection());
      });

      setConnection(conn);
      startConnection();
      setConnectionSubscriptions();
    });

    return () => {
      if (connectionRef.current) {
        connectionRef.current.stop();
        connectionRef.current = null;
      }

      editorStore.getState().reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  const startConnection = async () => {
    try {
      await connectionRef.current.start();
      joinGroup();
      if (reconnectionAttemptsLimitReached.current) {
        editorStore.getState().setIsReconnecting(false);
        toastMessage(enqueueSnackbar, closeSnackbar, t('editingEnabled'), 'success');
        reconnectionAttemptsLimitReached.current = false;
      }
    } catch (error) {
      if (error.message.includes('403')) {
        toastMessage(enqueueSnackbar, closeSnackbar, t('nonExistingContent'), 'warning');
        return navigate('/content');
      }
      if (reconnectionAttemptsLimitReached.current) {
        editorStore.getState().setIsReconnecting(true);
        toastMessage(enqueueSnackbar, closeSnackbar, t('reconnecting'), 'warning');
      } else {
        toastMessage(enqueueSnackbar, closeSnackbar, tError('ForbiddenDocument'), 'error');
      }
      setTimeout(startConnection, 5000);
    }
  };

  const joinGroup = (isReconnection = false) => {
    connectionRef.current
      .invoke(MESSAGE_TYPES.JOIN_GROUP, documentId, currentUser.id, isReconnection, true)
      .then(resp => {
        editorStore.getState().setDocumentContent(resp.deltaQuill);

        if (isReconnection) {
          editorStore.getState().setIsReconnecting(false);
          toastMessage(enqueueSnackbar, closeSnackbar, t('reconnected'), 'success');
        }

        checkRequestLoggingToConsole(currentUser.permissions, resp.requestIM) &&
          // eslint-disable-next-line no-console
          console.log(
            `Requested opening of content ${documentId}. ${resp.requestIM.requestType} request ${resp.requestIM.requestId} has been sent. Request cacheId is ${resp.requestIM.cacheId}. Model version is ${resp.requestIM.modelVersion}.`
          );
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const setConnectionSubscriptions = () => {
    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_CURRENT_VIEWERS_INFO, currentViewers => {
      editorStore.getState().setCurrentViewers(currentViewers);
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_DISABLE_EDITING_MESSAGE, resp => {
      toastMessage(
        enqueueSnackbar,
        closeSnackbar,
        t('editingDisabled', { username: resp }),
        'warning'
      );
      editorStore.getState().setParagraphData(null);
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_ENABLE_EDITING_MESSAGE, () => {
      toastMessage(enqueueSnackbar, closeSnackbar, t('editingEnabled'), 'success');
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_VERSION_INTELLIGIBILITY, resp => {
      editorStore.getState().setDocumentContent(resp);
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_INTELLIGIBILITY_INFO, resp => {
      editorStore.getState().setDocumentMetadata(resp);
      editorStore.getState().setIsRescoring(false);
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_DOCUMENT_INFO, documentInfo => {
      editorStore.getState().setParagraphData(documentInfo.paragraphs);
    });

    connectionRef.current.on(MESSAGE_TYPES.RECEIVE_DOCUMENT_SUGGESTIONS, resp => {
      if (editorStore.getState().isPendingReload) {
        editorStore.getState().setPendingSuggestions(resp.suggestions);
      } else {
        editorStore.getState().setDocumentSuggestions(resp.suggestions);
        editorStore.getState().setIsFetchingDocumentSuggestions(false);
        editorStore.getState().setIsFetchingEditedParagraphSuggestions(false);
        editorStore.getState().setHasLatestSuggestions(true);
      }
    });
  };

  const saveChanges = () => {
    editorStore.getState().setIsRescoring(true);
    connectionRef.current
      ?.invoke(MESSAGE_TYPES.SAVE_CHANGES, documentId, 'save')
      .then(res => {
        checkRequestLoggingToConsole(currentUser.permissions, res) &&
          // eslint-disable-next-line no-console
          console.log(
            `Automatic save is triggered for content ${documentId}. ${res.requestType} request ${res.requestId} has been sent. Request cacheId is ${res.cacheId}. Model version is ${res.modelVersion}.`
          );
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    stopAutosaveTimer();
  };

  const rescore = () => {
    editorStore.getState().setIsRescoring(true);
    connectionRef.current
      ?.invoke(MESSAGE_TYPES.RESCORE, documentId)
      .then(res => {
        checkRequestLoggingToConsole(currentUser.permissions, res) &&
          // eslint-disable-next-line no-console
          console.log(
            `Rescore is triggered for content ${documentId}. ${res.requestType} request ${res.requestId} has been sent. Request cacheId is ${res.cacheId}. Model version is ${res.modelVersion}.`
          );
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });

    stopRescoreTimer();
  };

  const startAutosaveTimer = () => {
    autosaveTimer.current = setInterval(saveChanges, autosaveIntervalDuration);
  };

  const stopAutosaveTimer = () => {
    autosaveTimer.current && clearInterval(autosaveTimer.current);
  };

  const resetAutosaveTimer = throttle(() => {
    stopAutosaveTimer();
    startAutosaveTimer();
  }, 1000);

  const startRescoreTimer = () => {
    rescoreTimer.current = setInterval(rescore, rescoreIntervalDuration);
  };

  const stopRescoreTimer = () => {
    rescoreTimer.current && clearInterval(rescoreTimer.current);
  };

  const resetRescoreTimer = throttle(() => {
    stopRescoreTimer();
    startRescoreTimer();
  }, 1000);

  const connectionProviderValues = useMemo(
    () => ({
      connection,
      editorStore,
      resetAutosaveTimer,
      resetRescoreTimer
    }),
    [connection, editorStore, resetAutosaveTimer, resetRescoreTimer]
  );

  return (
    <DocumentStateContext.Provider value={connectionProviderValues}>
      {children}
    </DocumentStateContext.Provider>
  );
};

DocumentStateProvider.propTypes = {
  children: PropTypes.any,
  documentId: PropTypes.number
};
