import React from 'react';
import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useStyles } from '../../containers/DocumentEditorPage/DocumentEditorPage.css';

export const EditsCounter = ({
  search,
  numberOfChanges,
  activeEditIndex,
  handleChangesUp,
  handleChangesDown
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [t] = useTranslation('common');

  const getOrderOfHighlightedEditText = () => {
    return numberOfChanges === 0 ? 0 : activeEditIndex + 1;
  };

  return (
    <Grid data-testid='editsCounter' container sx={{ height: '25px' }}>
      <p className={classes.editLabel}>
        {t('edit')} {search ? 0 : getOrderOfHighlightedEditText()} /{numberOfChanges}
      </p>
      {numberOfChanges > 0 && (
        <Grid item>
          <Grid container>
            <Divider className={classes.editDivider} orientation='vertical' />
            <ExpandMoreIcon
              className={classes.arrow}
              onClick={() => handleChangesUp(numberOfChanges)}
            />
            <ExpandLessIcon
              className={classes.arrow}
              onClick={() => handleChangesDown(numberOfChanges)}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

EditsCounter.propTypes = {
  search: PropTypes.string,
  numberOfChanges: PropTypes.number,
  handleChangesUp: PropTypes.func,
  handleChangesDown: PropTypes.func,
  activeEditIndex: PropTypes.number
};
