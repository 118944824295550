import React from 'react';

import { MenuItem, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { TextFieldStyle } from 'components';

const useStyles = makeStyles({
  menuItems: props => ({
    height: '50px !important',
    fontFamily: props.typography.fontFamilyPrimaryMedium,
    fontSize: props.typography.pxToRem(14),
    color: props.palette.gray.dark,
    '&:hover': {
      backgroundColor: props.palette.gray.lighter + ' !important',
      color: props.palette.gray.dark
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }),
  borderBottom: props => ({
    borderBottom: '0.5px solid ' + props.palette.gray.lighter
  })
});

export const FormDropdown = ({
  menuItemData,
  inputProps,
  touched,
  errors,
  fieldWidth,
  dataCyAttributes,
  placeholder,
  dataTestId
}) => {
  const theme = useTheme();

  const handleSelectColor = isTouched => {
    if (isTouched) {
      return theme.palette.gray.dark;
    } else {
      return theme.palette.gray.main;
    }
  };

  const classes = useStyles(theme);

  return (
    <Stack>
      <TextFieldStyle
        select
        data-testid={dataTestId}
        sx={{
          '& .MuiSelect-select span::before': {
            content: `"${placeholder}"`
          },
          '& .MuiOutlinedInput-root': {
            bgcolor: theme.palette.gray.white,
            color: handleSelectColor(touched),
            height: '50px',
            fontSize: theme.typography.pxToRem(14),
            '& fieldset': {
              borderColor: theme.palette.gray.light
            },
            '&:hover fieldset': {
              borderColor: theme.palette.gray.main
            },
            '& .MuiSelect-select': {
              fontFamily: theme.typography.fontFamilyPrimaryRegular
            }
          },
          '& .MuiSelect-icon': {
            border: '0px !important'
          },
          width: fieldWidth,
          '& .Mui-focused': {
            border: '1px solid',
            borderColor: theme.palette.gray.main,
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          }
        }}
        error={errors && Boolean(errors)}
        helperText={errors}
        {...inputProps}
        SelectProps={{
          SelectDisplayProps: {
            'data-cy': dataCyAttributes?.dropdown
          }
        }}>
        {menuItemData?.map((item, index) => (
          <MenuItem
            data-testid={`menuItem-${item.name}-${item.id}`}
            key={item.id}
            value={item.id}
            style={{
              marginBottom:
                menuItemData[menuItemData.length - 1].id === item.id ? '-8px' : undefined
            }}
            className={clsx(
              menuItemData.length - 1 !== index && classes.borderBottom,
              classes.menuItems
            )}
            data-cy={dataCyAttributes?.item + item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextFieldStyle>
    </Stack>
  );
};

FormDropdown.displayName = 'FormDropdown';

FormDropdown.propTypes = {
  menuItemData: PropTypes.array,
  touched: PropTypes.any,
  errors: PropTypes.any,
  inputProps: PropTypes.any,
  fieldWidth: PropTypes.any,
  dataCyAttributes: PropTypes.object,
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string
};
