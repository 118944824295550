import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { ComplexContent, MetricsRating, Scrollbar, Icons } from 'components';

export const KeyIssues = ({ intelligibilityData, isPlugin, displayComplexConcepts }) => {
  const [t] = useTranslation('common');
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up('xlm'));

  const displayKeyIssues = () => {
    const keyIssues = intelligibilityData?.textMetrics?.keyIssues.filter(
      ki => displayComplexConcepts || ki.metricName !== t('complexConcepts')
    );
    const sortedKeyIssues = keyIssues?.sort((a, b) => a.metricName.localeCompare(b.metricName));
    return keyIssues?.length === 0 ? (
      <Stack sx={{ flexDirection: 'row', mt: '15px', alignItems: 'center' }}>
        <Icons iconName={'checkedBlueIcon'} />
        <Typography
          sx={{
            fontSize: theme.typography.pxToRem(12),
            fontFamily: theme.typography.fontFamilyPrimaryRegular,
            color: theme.palette.gray.main,
            ml: '15px',
            mt: '1px'
          }}>
          {t('contentWithoutKeyIssues')}
        </Typography>
      </Stack>
    ) : (
      sortedKeyIssues?.map(item => {
        return (
          <MetricsRating
            key={uuidv4()}
            metricType={item.metricName}
            writingStyle={item.description}
            isKeyIssues
          />
        );
      })
    );
  };

  return (
    <>
      <Typography
        sx={{
          pt: '10px',
          color: theme.palette.gray.dark,
          fontSize: theme.typography.pxToRem(14),
          fontFamily: theme.typography.fontFamilySecondaryBold
        }}>
        {t('keyIssues')}
      </Typography>
      <Scrollbar sx={{ overflow: 'overlay', maxHeight: isBigScreen ? 220 : 140, zIndex: 1 }}>
        {displayKeyIssues()}
      </Scrollbar>
      {!isPlugin && (
        <Box sx={{ mt: '25px', mb: '10px', borderTop: `1px solid ${theme.palette.gray.light}` }}>
          <ComplexContent
            label='commonComplexWords'
            intelligibilityData={intelligibilityData}
            noDataText='noComplexWords'
            noDataIcon='textSlash'
          />
        </Box>
      )}
    </>
  );
};

KeyIssues.propTypes = {
  intelligibilityData: PropTypes.object,
  isPlugin: PropTypes.bool,
  displayComplexConcepts: PropTypes.bool
};
