import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useCollapseDrawer } from 'hooks';

import { MenuContent } from './MenuContent';

const DRAWER_WIDTH = 240;
const COLLAPSE_WIDTH = 72;

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  transition: theme.transitions.create('width', {
    duration: '1s'
  })
}));

const DashboardSidebar = ({
  isOpenSidebar,
  onCloseSidebar,
  setIsSwitchAccountDialogOpen,
  shouldCollapseSidebar
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { collapseClick, collapseHover } = useCollapseDrawer();
  const [collapse, setCollapse] = useState(
    shouldCollapseSidebar || JSON.parse(localStorage.getItem('isCollapse') ?? true)
  );

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (shouldCollapseSidebar) {
      setCollapse(true);
    }
  }, [shouldCollapseSidebar]);

  return (
    <RootStyle
      sx={{
        width: collapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
        ...(collapseClick && {
          position: 'absolute'
        })
      }}>
      <Drawer
        open
        variant='persistent'
        PaperProps={{
          sx: {
            borderRight: 0,
            width: DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
              duration: '1s'
            }),
            bgcolor: theme.palette.gray.white,
            ...(collapse && {
              width: COLLAPSE_WIDTH,
              transition: theme.transitions.create('width', {
                duration: '1s'
              })
            }),
            ...(collapseHover && {
              borderRight: 0,
              backdropFilter: 'blur(6px)',
              WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
              boxShadow: theme.customShadows.z20,
              bgcolor: alpha(theme.palette.background.default, 0.88)
            })
          }
        }}>
        <MenuContent
          setIsSwitchAccountDialogOpen={setIsSwitchAccountDialogOpen}
          isCollapse={collapse}
          setIsCollapse={setCollapse}
        />
      </Drawer>
    </RootStyle>
  );
};

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  setIsSwitchAccountDialogOpen: PropTypes.func,
  shouldCollapseSidebar: PropTypes.bool
};

export default DashboardSidebar;
