import React from 'react';

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { improvementPercentage } from 'helpers';

import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation';

export const CircularProgressDiagram = ({ intelligibilityData, isLoading }) => {
  const theme = useTheme();

  const improvementColor =
    intelligibilityData.intelligibilityImprovements < 0
      ? theme.palette.error.main
      : theme.palette.active.dark;

  return (
    <Box
      data-testid='circularProgressDiagram'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}>
      {isLoading && (
        <Box
          sx={{
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            width: 90,
            height: 90,
            display: 'flex',
            borderRadius: '50%',
            border: `12px solid ${theme.palette.gray.lighter}`
          }}>
          <Stack
            sx={{
              position: 'absolute',
              mb: '2.5px'
            }}>
            <LoadingAnimation height={30} width={30} />
          </Stack>
        </Box>
      )}
      {!isLoading && (
        <>
          <CircularProgress
            variant='determinate'
            sx={{ color: theme.palette.gray.lighter }}
            size={90}
            thickness={6}
            value={100}
          />
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              mt: !!intelligibilityData.intelligibilityImprovements && '5px'
            }}>
            <Typography
              variant='h4'
              sx={{
                color: theme.palette.primary.main,
                fontFamily: theme.typography.fontFamilySecondaryMedium,
                lineHeight: 0.75
              }}>
              {intelligibilityData.score}
            </Typography>
            {!!intelligibilityData.intelligibilityImprovements && (
              <Stack
                direction='row'
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  sx={{
                    color: improvementColor,
                    fontFamily: theme.typography.fontFamilySecondaryMedium
                  }}>
                  {improvementPercentage(intelligibilityData.intelligibilityImprovements)}%
                </Typography>
              </Stack>
            )}
          </Stack>
          <CircularProgress
            variant='determinate'
            thickness={6}
            size={90}
            sx={{
              color:
                theme.palette[`intelligibility_Level${intelligibilityData.intelligibilityLevel}`]
                  .dark,
              position: 'absolute',
              left: 0
            }}
            value={intelligibilityData.score}
          />
        </>
      )}
    </Box>
  );
};

CircularProgressDiagram.propTypes = {
  intelligibilityData: PropTypes.object,
  isLoading: PropTypes.bool
};
