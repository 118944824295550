import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { setTableBindings } from 'components/Table/Table';
import {
  calculateIndices,
  checkPermission,
  debounce,
  displayBorder,
  MESSAGE_TYPES,
  permissions,
  removePreviousSelectionWhileAddingComments,
  scrollToElementIfNeeded,
  stringifyCommentValues,
  toastMessage
} from 'helpers';
import { useCollapseDrawer, useSimplifications } from 'hooks';
import { DocumentStateContext, UserContext } from 'providers';
import { useStoreFields } from 'stores';

import { setSelectedListBtn, setEditorListBindings, setNumberingAndStyle } from './CustomList.js';
import { useStyles } from './DocumentEditorComponent.css.js';
import {
  checkIfBoldIsActiveOnNewListItemLine,
  findEditsPositionFromText,
  scrollToNewlyInsertedElement,
  getSectionDimensionData
} from './DocumentEditorComponentMethods.js';
import './SimplificationBlots.js';
import './ExclusionBlot.js';
import './QuillClipboard.js';
import './HeadingAttribute.js';
import { formats } from './QuillFormats';
import { ReloadSuggestionInlineButton } from './ReloadSuggestionsInlineButton';
import { modules } from '../../containers/DocumentEditorPage/CustomInlineToolbar/CustomInlineToolbar';
import { ParagraphLevelSuggestionsDialog } from '../../containers/DocumentEditorPage/ParagraphLevelSuggestionsDialog/ParagraphLevelSuggestionsDialog.js';
import 'react-quill/dist/quill.snow.css';
import './HighlightCommentAttribute.js';
import './CommentAttribute.js';
import './quill-override.css';

export const DocumentEditorComponent = ({
  scrollRef,
  commentSelection,
  setCommentSelection,
  commentId,
  isCommentingActive,
  selection,
  selectionDelta,
  comments,
  handleSectionsDimensionData,
  search,
  activeIndex,
  resultsIndices,
  setActiveHighlightIndex,
  getParentElement,
  onEditorClick,
  removeSuggestionsDueToManualTextChange,
  dialogRef
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [t] = useTranslation('common');
  const [currentHighlight, setCurrentHighlight] = useState(null);
  const [listSelection, setListSelection] = useState({ index: -1, length: 0 });
  const quillRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);

  const { connection, resetAutosaveTimer, resetRescoreTimer } = useContext(DocumentStateContext);
  const hasEditContentPermission = checkPermission(user.permissions, permissions.CONTENT_EDIT);

  const handleQuillEditorRef = el => {
    if (el && !quillRef.current) {
      quillRef.current = el.getEditor();
      setQuillEditor(quillRef.current);
    }
  };

  const {
    documentId,
    documentContent,
    isReconnecting,
    isFetchingSelectedTextSimplifications,
    isRephraseSelected,
    setUndoRedoStack,
    quillEditor,
    setQuillEditor,
    selectedText,
    setSelectedText,
    nextComplexEntityInSentence,
    isRephraseDialogOpen,
    isReplacementDialogOpen,
    setIsFetchingEditedParagraphSuggestions,
    hasLatestSuggestions,
    setHasLatestSuggestions
  } = useStoreFields([
    'documentId',
    'documentContent',
    'isReconnecting',
    'isFetchingSelectedTextSimplifications',
    'isRephraseSelected',
    'setUndoRedoStack',
    'quillEditor',
    'setQuillEditor',
    'selectedText',
    'setSelectedText',
    'nextComplexEntityInSentence',
    'isRephraseDialogOpen',
    'isReplacementDialogOpen',
    'setIsFetchingEditedParagraphSuggestions',
    'hasLatestSuggestions',
    'setHasLatestSuggestions'
  ]);

  const { getSelectedTextSuggestions, selectedTextSuggestions, setSelectedTextSuggestions } =
    useSimplifications({ quillEditor });

  let changeTimeout;
  let textChanged = false;

  const previousSearch = useRef('');

  const { isCollapse, onOpenEditorCollapse } = useCollapseDrawer();
  const isMobile = useMediaQuery(theme.breakpoints.down(displayBorder.small));

  const parseCommentValues = documentContent => {
    const documentContentCopy = cloneDeep(documentContent);
    const deltaChangesWithParsedCommentAttribute = documentContentCopy.ops.map(op => {
      if (op?.attributes?.comment && typeof op.attributes.comment === 'string') {
        op.attributes.comment = JSON.parse(op.attributes.comment);
      }
      return op;
    });
    return { ops: deltaChangesWithParsedCommentAttribute };
  };

  const formattedDocumentContent = parseCommentValues(documentContent);

  useEffect(() => {
    if (isRephraseSelected && selectedText?.length > 1) {
      getSelectedTextSuggestions();
    }
  }, [selectedText]);

  useEffect(() => {
    if (isRephraseSelected && selection.current?.length > 0) {
      setSelectedText(selection.current);
    }
  }, [isRephraseSelected]);

  useEffect(() => {
    if (connection) {
      quillEditor && setEditorListBindings(quillEditor);
      quillEditor && setTableBindings(quillEditor);
      quillEditor?.setContents(formattedDocumentContent);
      quillEditor?.history.clear();
      quillEditor && !isMobile && onOpenEditorCollapse();
      documentContent && setNumberingAndStyle(document.getElementsByTagName('list-item'));

      connection.on(MESSAGE_TYPES.RECEIVE_CHANGES, changes => {
        const formattedChanges = parseCommentValues(changes);
        quillEditor.updateContents(formattedChanges);
        getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
      });
    }
  }, [quillEditor, connection]);

  useEffect(() => {
    if (nextComplexEntityInSentence) {
      selection.current = {
        index: nextComplexEntityInSentence.suggestionRange.startIndex,
        length: 0
      };

      // Get scroll position before setting selection
      const scrollContainer = quillEditor?.scrollingContainer;
      const scrollPosition = scrollContainer?.scrollTop;

      quillEditor.setSelection(selection.current, 'silent');

      // Restore scroll position
      scrollContainer.scrollTop = scrollPosition;

      onEditorClick(nextComplexEntityInSentence);
    }
  }, [nextComplexEntityInSentence]);

  const editedWords = useMemo(() => {
    return findEditsPositionFromText(formattedDocumentContent);
  }, [documentContent]);

  useEffect(() => {
    searchText(previousSearch.current, true);
    previousSearch.current = search;
    searchText(search, false);
    setCurrentHighlight(null);
  }, [search, documentContent]);

  const searchText = (text, remove) => {
    setActiveHighlightIndex(0);
    const documentText = quillEditor?.getText();
    const indices = calculateIndices(documentText, text);
    resultsIndices.current = indices;
    if (indices.length > 0) {
      highlightSearchedWords(indices, text.length, remove);
    }
  };

  const highlightSearchedWords = (indices, wordLength, remove) => {
    if (search !== '') {
      const notSearchedEdits = editedWords.filter(word => !word.text.includes(search));
      if (editedWords.length !== notSearchedEdits.length) {
        //When edited word is searched, highlight format isn't shown unless insertedText format is removed
        if (notSearchedEdits.length !== 0) {
          //Iterating through edits that don't match searched text and adding insertedText format
          notSearchedEdits.forEach(word => {
            quillEditor.formatText(word.index, word.wordLength, 'insertedText', false);
          });
        }
      }
    }
    indices.forEach(index => {
      if (search !== '') {
        quillEditor.formatText(index, wordLength, 'insertedText', false);
      }

      quillEditor.formatText(index, wordLength, 'highlight', remove ? false : { active: false });
    });
  };

  const formatMatchedWords = (startIndex, active) => {
    quillEditor?.formatText(startIndex, search?.length, 'highlight', {
      active: active
    });

    if (active) {
      const activeHighlight = getParentElement.current.getElementsByClassName('active-highlight');
      scrollToElementIfNeeded(activeHighlight[0], scrollRef);
    }
  };

  useEffect(() => {
    if (resultsIndices.current.length > 0) {
      setCurrentHighlight(resultsIndices.current[activeIndex]);
      if (currentHighlight !== null) {
        //remove active color and add non-active to previously selected element
        formatMatchedWords(currentHighlight, false);
      }
      //add active color
      formatMatchedWords(resultsIndices.current[activeIndex], true);
    }
  }, [activeIndex, resultsIndices.current]);

  const setIndicatorsAfterSidebarChange = (interval = 1000) => {
    setTimeout(() => {
      getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
    }, interval);
  };

  const debounceSetSelectionDimensionData = debounce(() => {
    getSectionDimensionData(handleSectionsDimensionData, setNumberingAndStyle);
  }, 200);

  useEffect(() => {
    setIndicatorsAfterSidebarChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapse]);

  const checkNodeStyling = node => {
    return scrollElement(node) ? node : findActualScrollingContainer(node.parentNode);
  };

  const style = (nodeElem, prop) => window.getComputedStyle(nodeElem, null).getPropertyValue(prop);
  const scrollElement = nodeElem =>
    /(scroll)/.test(
      style(nodeElem, 'overflow') + style(nodeElem, 'overflow-y') + style(nodeElem, 'overflow-x')
    );

  const findActualScrollingContainer = node => {
    return !node || node === document.body ? document.body : checkNodeStyling(node);
  };

  useEffect(() => {
    if (quillEditor && listSelection) {
      quillEditor.scrollingContainer = findActualScrollingContainer(quillEditor.container);
      selection.current && setSelectedListBtn(quillEditor, selection.current);
    }
  });

  useEffect(() => {
    // setContents() set selection to 0, and triggers onSelectionChange function(which overrides selection state)
    // it's necessary to save the selection value, before setting new content, to keep it after the setting content
    quillEditor?.setContents(formattedDocumentContent);

    // clearing history after setting the content
    quillEditor?.history.clear();
    setUndoRedoStack({ undo: [], redo: [] });
    textChanged = false;
    documentContent && setNumberingAndStyle(document.getElementsByTagName('list-item'));
  }, [quillEditor, documentContent]);

  const debounceSetUndoRedoStack = debounce(history => {
    setUndoRedoStack(history);
  }, 500);

  const opHasDeleteKey = delta => {
    return (
      delta.ops.length <= 2 &&
      delta.ops.some(item => Object.hasOwn(item, 'delete') && item.delete !== null)
    );
  };

  const handleOnChange = (_, delta, changeType) => {
    selectionDelta.current = delta;

    if (opHasDeleteKey(delta) && comments?.length) {
      removeCommentCards();
    }
    selection.current = quillEditor.getSelection();

    if (changeType === 'user') {
      removeSuggestionsDueToManualTextChange(delta);

      if (
        delta.ops?.some(
          op => op.attributes?.listItem !== undefined || op.attributes?.indent !== undefined
        )
      ) {
        setNumberingAndStyle(document.getElementsByTagName('list-item'));
      }

      const stringifiedDelta = stringifyCommentValues(delta);

      commentSelection.length === -1 &&
        connection?.invoke(MESSAGE_TYPES.BROADCAST_CHANGES, documentId, stringifiedDelta);

      // start timers after the first text change and reset them later, on every change(textual or non-textual)
      if (
        delta.ops.some(
          o =>
            o.insert !== undefined ||
            o.delete !== undefined ||
            (o.attributes !== undefined && o.attributes.comment === undefined)
        )
      ) {
        textChanged = true;
      }

      if (textChanged) {
        resetAutosaveTimer();
        resetRescoreTimer();
        setHasLatestSuggestions && setHasLatestSuggestions(false);
      }

      debounceSetUndoRedoStack({ ...quillEditor.history.stack });
    }

    debounceSetSelectionDimensionData();

    // reset timeout to avoid accessing DOM tree too often (DOM access is slow on Safari)
    clearTimeout(changeTimeout);
  };

  const getSelectionOffset = () => {
    const selectedText = quillEditor.getSelection();
    if (selectedText) {
      const bounds = quillEditor.getBounds(selectedText.index, 1);
      return bounds.bottom;
    }

    return 0;
  };

  const onChangeSelection = (range, source) => {
    // Editor loses selection on modal opening
    // Don't save the selection state when the modal is opened to be able to set the selection again on the modal closing
    selection.current = range;

    if (isCommentingActive && range) {
      removePreviousSelectionWhileAddingComments(commentSelection, quillEditor, commentId);
      setCommentSelection(range);
    }

    if (isRephraseSelected && range?.length > 1) {
      setSelectedText(range);
    }

    if (source === 'paste') {
      const offSet = getSelectionOffset();
      scrollToNewlyInsertedElement(scrollRef, offSet);
    }

    setListSelection(quillEditor.getSelection());
  };

  const onKeyUpHandler = e => {
    if (e.key === 'Enter') {
      const offSet = getSelectionOffset();
      scrollToNewlyInsertedElement(scrollRef, offSet);
      checkIfBoldIsActiveOnNewListItemLine(quillEditor);
    }

    if (e.ctrlKey && (e.key === 'x' || e.key === 'X')) {
      isReloadBtnDisplayed && reloadEditedParagraphSuggestions();
    }
  };

  const extractCommentIds = () => {
    const commentIds = comments?.map(comment => comment.id);
    const editorContent = quillEditor?.getContents();
    const commentIdsFromText = editorContent?.ops.flatMap(op => op.attributes?.comment || []);
    return {
      commentCardIds: commentIds,
      commentTextIds: commentIdsFromText
    };
  };

  const removeCommentCards = () => {
    const { commentCardIds, commentTextIds } = extractCommentIds();
    const matchingItems = commentCardIds?.filter(item => !commentTextIds.includes(item));
    matchingItems.length &&
      connection
        ?.invoke(MESSAGE_TYPES.REMOVE_COMMENTS, matchingItems)
        .then(() => {
          toastMessage(
            enqueueSnackbar,
            closeSnackbar,
            t('commentsDeletedSuccess', { count: matchingItems.length }),
            'success'
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
  };

  const reloadEditedParagraphSuggestions = () => {
    setIsFetchingEditedParagraphSuggestions(true);
    connection.invoke(MESSAGE_TYPES.GET_DOCUMENT_SUGGESTIONS, documentId);
  };

  const handleEditorClick = e => {
    e.stopPropagation();
    if (!isRephraseSelected) {
      onEditorClick();
    }

    setListSelection(quillEditor.getSelection());
  };

  const isReloadBtnDisplayed =
    !isMobile && !hasLatestSuggestions && quillEditor?.hasFocus() && hasEditContentPermission;

  return (
    <div
      style={{
        pointerEvents: isRephraseDialogOpen || isReplacementDialogOpen ? 'none' : 'auto',
        marginLeft: '2.5rem'
      }}
      className={classes.editorContainer}
      data-text-editor='editorContainer'
      id='editorContainer'
      onClick={handleEditorClick}>
      {isReloadBtnDisplayed && (
        <ReloadSuggestionInlineButton
          topPosition={quillEditor.getBounds(selection.current.index, 1).top}
          reloadEditedParagraphSuggestions={reloadEditedParagraphSuggestions}
        />
      )}
      <ParagraphLevelSuggestionsDialog
        dialogRef={dialogRef}
        sentenceSuggestions={selectedTextSuggestions || []}
        setSentenceSuggestions={setSelectedTextSuggestions}
        quillEditor={quillEditor}
        scrollRef={scrollRef}
        isFetchingSuggestions={isFetchingSelectedTextSimplifications}
        setSelectedText={setSelectedText}
        selectedText={selectedText}
        isRephraseSelected={isRephraseSelected}
        isOpen={selectedText?.length > 1}
      />
      <ReactQuill
        readOnly={
          isMobile ||
          isReconnecting ||
          (isRephraseSelected && !selectedText) ||
          (!hasEditContentPermission && !isCommentingActive)
        }
        ref={handleQuillEditorRef}
        theme={'snow'}
        modules={modules}
        formats={formats}
        onChangeSelection={onChangeSelection}
        onChange={handleOnChange}
        onKeyUp={onKeyUpHandler}
        // eslint-disable-next-line quotes
        bounds={"[data-text-editor='editorContainer']"}
      />
    </div>
  );
};

DocumentEditorComponent.propTypes = {
  scrollRef: PropTypes.object,
  commentSelection: PropTypes.object,
  setCommentSelection: PropTypes.func,
  commentId: PropTypes.string,
  isCommentingActive: PropTypes.bool,
  selection: PropTypes.object,
  selectionDelta: PropTypes.object,
  comments: PropTypes.array,
  handleSectionsDimensionData: PropTypes.func,
  search: PropTypes.string,
  activeIndex: PropTypes.number,
  resultsIndices: PropTypes.object,
  setActiveHighlightIndex: PropTypes.func,
  getParentElement: PropTypes.any,
  onEditorClick: PropTypes.func,
  removeSuggestionsDueToManualTextChange: PropTypes.func,
  dialogRef: PropTypes.object
};
