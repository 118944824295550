import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { faTriangleExclamation } from 'resources/fontawesome/solid/faTriangleExclamation';

const useStyles = makeStyles(theme => ({
  formField: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${theme.palette.gray[400]}`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      },
      '&:hover': {
        backgroundColor: theme.palette.gray[50],
        border: `1px solid ${theme.palette.indigo[500]}`
      },
      '&.Mui-focused': {
        border: `1px solid ${theme.palette.indigo[500]}`,
        boxShadow: `0px 0px 3px 3px ${theme.palette.indigo[200]}`
      },
      '&.Mui-error': {
        border: `1px solid ${theme.palette.error.main}`
      },
      '&.Mui-error.Mui-focused': {
        boxShadow: '0px 0px 3px 3px rgba(255, 225, 225, 1)'
      },
      '&.MuiInputBase-multiline': {
        padding: '0px'
      },
      '&.Mui-error .MuiOutlinedInput-input': {
        paddingRight: '8px'
      }
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0px'
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: theme.typography.fontFamilyPrimaryRegular,
      color: theme.palette.gray.dark,
      fontSize: theme.typography.pxToRem(14),
      padding: '5px 16px',
      '&::placeholder': {
        color: theme.palette.gray[600],
        opacity: 1
      }
    },
    '& .MuiFormHelperText-root': {
      paddingTop: '5px',
      color: `${theme.palette.error.main} !important`,
      margin: '0px',
      fontFamily: theme.typography.fontFamilyPrimaryRegular
    }
  },
  errorIcon: {
    height: '18px',
    width: '18px',
    color: theme.palette.error.icon,
    paddingRight: '16px',
    marginLeft: '0px'
  }
}));

export const FormField = ({ variant = 'large', dataCy, ...props }) => {
  const classes = useStyles();
  const { error } = props;

  return (
    <TextField
      {...props}
      className={classes.formField}
      data-testid='formField'
      sx={{
        '& .MuiOutlinedInput-root': {
          minHeight: variant === 'large' ? '50px' : '40px'
        }
      }}
      InputProps={{
        endAdornment: error && (
          <InputAdornment position='end'>
            <FontAwesomeIcon icon={faTriangleExclamation} className={classes.errorIcon} />
          </InputAdornment>
        )
      }}
      inputProps={{
        'data-cy': dataCy
      }}
    />
  );
};

FormField.propTypes = {
  dataCy: PropTypes.string,
  error: PropTypes.bool,
  variant: PropTypes.string
};
