import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Grid, ListItemText, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { Logo, MHidden, Scrollbar, LightTooltip } from 'components';
import { useCollapseDrawer } from 'hooks';
import { UserContext } from 'providers';
import { faRightFromBracket } from 'resources/fontawesome/light/faRightFromBracket';
import { tokenService } from 'services/token.service';

import { AccountSettings } from './AccountSettings';
import { IconCollapse } from './IconCollapse.js';
import { NavSection } from './NavSection';
import { sidebarConfig } from './SidebarConfig';

export const MenuContent = ({ setIsSwitchAccountDialogOpen, isCollapse, setIsCollapse }) => {
  const theme = useTheme();
  const [t] = useTranslation('common');
  const { logoutChannel } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { onToggleCollapse: onToggleCollapseContext } = useCollapseDrawer();

  const ListItemTextStyle = styled(ListItemText)(() => ({
    marginLeft: '0.5rem',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.gray.main
  }));

  const isPing = 'PingId';

  const logoutButtonStyle = {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(1.6),
    marginRight: theme.spacing(1.6),
    marginTop: '0.5rem',
    marginBottom: '1rem',
    fontFamily: theme.typography.fontFamilyPrimaryRegular,
    cursor: 'pointer',
    height: 40,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.gray.lightest
    }
  };

  const onToggleCollapse = () => {
    setIsCollapse(!isCollapse);
    localStorage.setItem('isCollapse', (!isCollapse).toString());
    onToggleCollapseContext();
  };

  const handleLogout = async () => {
    try {
      logoutChannel.postMessage('Logout');
    } catch (error) {
      enqueueSnackbar(t('unableToLogout'), { variant: 'error' });
    }
  };

  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        },
        bgcolor: theme.palette.gray.white,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        borderRight: `1px solid ${theme.palette.gray.light}`
      }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        sx={{ p: 2, pt: 4 }}
        data-testid='menuContent'>
        <Box
          component={RouterLink}
          to={'/dashboard'}
          spacing={3}
          sx={{
            textDecoration: 'none',
            display: 'inline-flex',
            ...(isCollapse && {
              alignItems: 'center'
            })
          }}>
          <Logo variant={isCollapse ? 'smallLogo' : 'logoWithText'} />
        </Box>

        <MHidden width='lgDown'>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              WebkitJustifyContent: 'flex-end'
            }}>
            <IconCollapse onToggleCollapse={onToggleCollapse} isCollapsed={isCollapse} />
          </Grid>
        </MHidden>
      </Stack>
      <NavSection navConfig={sidebarConfig} isSidebarCollapsed={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      {tokenService.getProvider() !== isPing && (
        <AccountSettings
          isCollapse={isCollapse}
          setIsSwitchAccountDialogOpen={setIsSwitchAccountDialogOpen}
        />
      )}
      <Divider sx={{ mx: theme.spacing(2.2) }} />
      {tokenService.getProvider() !== isPing && (
        <LightTooltip title={isCollapse && t('logout')} placement='right'>
          <Stack data-cy='logoutButton' onClick={handleLogout} sx={logoutButtonStyle}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              style={{ width: '16px', height: '16px', paddingLeft: '15px' }}
            />

            {!isCollapse && <ListItemTextStyle disableTypography primary='logout' />}
          </Stack>
        </LightTooltip>
      )}
    </Scrollbar>
  );
};

MenuContent.propTypes = {
  setIsSwitchAccountDialogOpen: PropTypes.func,
  setIsCollapse: PropTypes.func,
  isCollapse: PropTypes.bool
};
