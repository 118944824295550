export const MESSAGE_TYPES = {
  ADD_APPLIED_REPLACEMENT: 'AddAppliedReplacement',
  ADD_COMMENT: 'AddComment',
  APPLY_SIMPLIFICATION: 'ApplySimplification',
  APPLY_TEXT_SIMPLIFICATION: 'ApplySelectedTextSimplification',
  BROADCAST_CHANGES: 'BroadcastChanges',
  CLOSE_SIMPLIFICATIONS: 'CloseSimplifications',
  GET_CLICKED_SECTION_RANGE: 'GetClickedSectionRange',
  GET_COMMENTS: 'GetComments',
  GET_CLICKED_SENTENCE_RANGE: 'GetClickedSentenceRange',
  GET_DOCUMENT_SUGGESTIONS: 'GetDocumentSuggestions',
  GET_EDITORS_INFO: 'GetEditorsInfo',
  GET_FRAME_NODE_INTELLIGIBILITY: 'GetFrameNodeIntelligibility',
  GET_TEXT_NODE_INTELLIGIBILITY: 'GetTextNodeIntelligibility',
  GET_PASSIVE_VOICE_SIMPLIFICATIONS: 'GetPassiveVoiceSimplifications',
  GET_SECTION_SIMPLIFICATIONS: 'GetSectionSimplifications',
  GET_SELECTED_TEXT_SUGGESTIONS: 'GetSelectedTextSuggestions',
  GET_SENTENCE_SUGGESTIONS: 'GetSentenceSuggestions',
  GET_SENTENCE_SIMPLIFICATIONS: 'GetSentenceSimplifications',
  GET_SUGGESTION_REPLACEMENTS: 'GetSuggestionReplacements',
  HANDLE_SUGGESTION_ACTION: 'HandleSuggestionAction',
  HIDE_REPLACEMENTS: 'HideReplacement',
  JOIN_GROUP: 'JoinGroup',
  RECEIVE_COMMENTS_CHANGED: 'ReceiveCommentsChanged',
  RECEIVE_CHANGES: 'ReceiveChange',
  RECEIVE_CURRENT_VIEWERS_INFO: 'ReceiveCurrentViewersInfo',
  RECEIVE_DISABLE_EDITING_MESSAGE: 'ReceiveDisableEditingMessage',
  RECEIVE_DOCUMENT_INFO: 'ReceiveDocumentInfo',
  RECEIVE_DOCUMENT_SUGGESTIONS: 'ReceiveDocumentSuggestions',
  RECEIVE_INTELLIGIBILITY_INFO: 'ReceiveIntelligibilityInfo',
  RECEIVE_ENABLE_EDITING_MESSAGE: 'ReceiveEnableEditingMessage',
  RECEIVE_SECTION_SIMPLIFICATIONS: 'ReceiveSectionSimplifications',
  RECEIVE_SELECTED_TEXT_SIMPLIFICATIONS: 'ReceiveSelectedTextSimplifications',
  RECEIVE_SENTENCE_SUGGESTIONS: 'ReceiveSentenceSuggestions',
  RECEIVE_SENTENCE_SIMPLIFICATIONS: 'ReceiveSentenceSimplifications',
  RECEIVE_SUGGESTION_RELOAD_NOTIFICATION: 'ReceiveSuggestionReloadNotification',
  RECEIVE_SUGGESTION_REPLACEMENTS: 'ReceiveSuggestionReplacements',
  RECEIVE_VERSION_INTELLIGIBILITY: 'ReceiveVersionIntelligibility',
  REMOVE_COMMENTS: 'RemoveComments',
  RESCORE: 'Rescore',
  RESOLVE_COMMENT: 'ResolveComment',
  SAVE_CHANGES: 'SaveChanges',
  SHOW_COMPLEXITY_MAP: 'ShowComplexityMap'
};
